import { connect } from "react-redux";
import React from "react";
import AccountLoginForm from "../modules/account/login/account-login";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="page-wrapper">
        <div>
          <AccountLoginForm />
        </div>
      </div>
    );
  }
}

export { LoginPage };

//= =========================================
//		MAP STATE TO PROPS
//= =========================================

export default connect(null, null)(LoginPage);
