import React, { useState } from "react";

import FileLoader from "../file-loader";

interface CSVFileLoaderProps {
  onParse: (parsedCSV: {
    headers: Array<string>;
    rows: Array<Record<string, string>>;
  }) => void;
}

const CSVFileLoader = ({ onParse }: CSVFileLoaderProps): JSX.Element => {
  // returns an array of the strings in double quotes from the text but ignoring escaped quotes
  const parseQuotedLine = (line: string): Array<string> => {
    const regex = /"((?:[^"\\]|\\.)*)"/g;
    const matches = [];
    let match;
    while ((match = regex.exec(line)) !== null) {
      matches.push(match[1]);
    }
    return matches;
  };

  const parseUnquotedLine = (line: string): Array<string> => {
    return line.split(",");
  };

  // parse csv file to json, assuming first row is header
  const parseCSV = (text: string | ArrayBuffer): void => {
    //console.log(text);
    let csv = "";
    if (text instanceof ArrayBuffer) {
      csv = new TextDecoder("utf-8").decode(text);
    } else if (typeof text === "string") {
      csv = text;
    }

    const quoted = csv[0] === '"';

    const lines = csv.split(/\r?\n/);
    const headers = quoted
      ? parseQuotedLine(lines[0])
      : parseUnquotedLine(lines[0]);

    let i = 1;
    const rows = [];
    for (; i < lines.length; i++) {
      const line = quoted
        ? parseQuotedLine(lines[i])
        : parseUnquotedLine(lines[i]);
      const obj = {};
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = line[j];
      }
      rows.push(obj);
    }
    onParse({ headers, rows });
  };

  return (
    <React.Fragment>
      <FileLoader onLoad={parseCSV} accept={[".csv"]} />
    </React.Fragment>
  );
};

export default CSVFileLoader;
