import { segmentRecordActionTypes } from "./segment-record-reducer";
import { normalize } from "normalizr";
import { arrayWrap } from "../../easy-reducer.js";
import { segmentRecordSchema } from "../../normalizr-schema.js";
import { normalizedDataReceived } from "../app/app-actions.js";

export function segmentRecordReceived(segmentRecord) {
  const segmentRecords = normalize(arrayWrap(segmentRecord), [
    segmentRecordSchema,
  ]);
  return normalizedDataReceived(segmentRecords.entities);
}

export function createSegmentRecord(id, athleteId, trainerId, segmentRecord) {
  return {
    type: segmentRecordActionTypes.createSegmentRecord,
    id,
    athleteId,
    trainerId,
    segmentRecord,
  };
}

export function clearSegmentRecords() {
  return { type: "CLEAR_SEGMENT_RECORDS" };
}

export function getSegmentRecordHistoryByAthleteId(athleteId) {
  return { type: segmentRecordActionTypes.getHistoryById, athleteId };
}

export function getSegmentRecordHeaderById(segmentRecordId) {
  return {
    type: segmentRecordActionTypes.getSegmentRecordHeaderById,
    segmentRecordId,
  };
}

export function getSegmentRecordHeaderByIdList(segmentRecordIds) {
  return {
    type: segmentRecordActionTypes.getSegmentRecordHeaderByIdList,
    segmentRecordIds,
  };
}

export function toggleSegmentRecordSelection(segmentRecordId) {
  return {
    type: segmentRecordActionTypes.toggleSegmentRecordSelection,
    segmentRecordId,
  };
}

export function clearSegmentRecordSelection() {
  return { type: segmentRecordActionTypes.clearSegmentRecordSelection };
}

export function getSegmentRecordById(segmentRecordId) {
  return {
    type: segmentRecordActionTypes.getSegmentRecordById,
    segmentRecordId,
  };
}

export function getSegmentRecordsByIdList(segmentRecordIds) {
  return {
    type: segmentRecordActionTypes.getSegmentRecordsByIdList,
    segmentRecordIds,
  };
}

export function getSessionsBySegmentRecordIdList(segmentRecordIds) {
  return {
    type: segmentRecordActionTypes.getSessionsBySegmentRecordIdList,
    segmentRecordIds,
  };
}

export function segmentRecordRegistrationSent(id) {
  return {
    id,
    type: segmentRecordActionTypes.segmentRecordRegistrationSent,
  };
}

export function clearFormSuccess(id) {
  return {
    id,
    type: segmentRecordActionTypes.clearFormSuccess,
  };
}

export function segmentRecordRegistered(id, errors = null) {
  return {
    type: segmentRecordActionTypes.segmentRecordRegistered,
    id,
    errors,
  };
}
