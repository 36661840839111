import { userFeedbackActionTypes } from "./user-feedback-reducer";

export function clearFeedback() {
  return { type: "CLEAR_FEEDBACK" };
}

export function postUserFeedback(userId, userFeedback) {
  return {
    type: userFeedbackActionTypes.postUserFeedback,
    userId,
    userFeedback,
  };
}

export function feedbackSent() {
  return { type: userFeedbackActionTypes.feedbackSent };
}

export function feedbackReceived() {
  return { type: userFeedbackActionTypes.feedbackReceived };
}

export function resetFeedbackState() {
  return { type: userFeedbackActionTypes.resetFeedbackState };
}

export function feedbackError() {
  return { type: userFeedbackActionTypes.feedbackError };
}
