import { connect } from "react-redux";
import React from "react";
import SegmentRecordResultsCard from "../modules/segment-record/results/card/results-card";
import { getSegmentRecord, getSegmentRecordResultData } from "../selectors";
import { getSession } from "../selectors";
import {
  getSegmentRecordHeaderById,
  getSegmentRecordById,
} from "../modules/segment-record/segment-record-actions";
import { getNewAthleteById } from "../modules/athlete/athlete-actions";
import UserFeedbackDrawer from "../modules/user-feedback/drawer/user-feedback-drawer";
import SegmentRecordCardioRecordGraph from "../modules/segment-record/results/graph/record/cardio/cardio-record-graph";
import SegmentRecordResistanceRecordGraph from "../modules/segment-record/results/graph/record/resistance/resistance-record-graph";
import SegmentRecordFastFeetGraph from "../modules/segment-record/results/graph/record/fastfeet/fastfeet-record-graph";
import SegmentRecordBaselineGraphs from "../modules/segment-record/results/graph/record/baseline/baseline-record-graphs";

import AppBar from "../modules/common/app-bar/app-bar";
import CommonSplitBanner from "../modules/common/banner/split/common-split-banner";
import CommonStyledDropdown from "../modules/common/styled-dropdown/common-styled-dropdown";
import CommonCenterBanner from "../modules/common/banner/center/common-center-banner";
import SideNavigation from "../modules/side-navigation/side-navigation";

class SegmentRecordDisplayPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showGraphs: false,
      displayType: this.props.displayType ? this.props.displayType : "card",
    };
    this.setDisplayType = this.setDisplayType.bind(this);
  }

  setDisplayType(value) {
    this.setState({ displayType: value });
    this.props.history.push(`${this.props.cleanBaseURL}?display=${value}`);
  }

  componentDidMount() {
    this.props.getSegmentRecordById(this.props.selectedSegmentRecordId);
    if (!this.props.segmentRecord) {
      this.props.getSegmentRecordHeaderById(this.props.selectedSegmentRecordId);
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.session != this.props.session && this.props.session) {
      if (!this.props.athlete) {
        this.props.getNewAthleteById(this.props.session.user_id);
      }
    }
  }

  render() {
    let name;
    let athleteId;
    if (this.props.athlete) {
      name = this.props.athlete.name;
      athleteId = this.props.athlete.id;
    }
    let tests = [];
    if (this.props.resultData.resultTypes) {
      if (
        this.props.resultData.resultTypes.targetedCardio &&
        !this.props.resultData.resultTypes.baseline
      ) {
        tests.push(<div key="cardio-signifier">Cardio Results</div>);
      }
      if (
        this.props.resultData.resultTypes.resistance &&
        !this.props.resultData.resultTypes.baseline
      ) {
        tests.push(<div key="resistance-signifier">Resistance Results</div>);
      }
      if (
        this.props.resultData.resultTypes.fastFeet &&
        !this.props.resultData.resultTypes.baseline
      ) {
        tests.push(<div key="resistance-signifier">Speed Steps Results</div>);
      }
      if (this.props.resultData.resultTypes.baseline) {
        tests.push(<div key="baseline-signifier">Baseline Results</div>);
      }
    }
    // console.log("dd", this.props.segmentRecord, this.props.resultData);
    let display;
    if (this.state.displayType == "card") {
      display = (
        <SegmentRecordResultsCard
          segmentRecord={this.props.segmentRecord}
          resultData={this.props.resultData}
        />
      );
    } else if (this.state.displayType == "graph") {
      let cardioGraph;
      let resistanceGraph;
      let fastFeetGraph;
      let baselineGraphs;
      if (this.props.resultData.resultTypes) {
        if (
          this.props.resultData.resultTypes.targetedCardio &&
          !this.props.resultData.resultTypes.baseline
        ) {
          cardioGraph = (
            <SegmentRecordCardioRecordGraph
              sessionId={this.props.selectedSegmentRecordId}
              history={this.props.history}
            />
          );
        }
        if (
          this.props.resultData.resultTypes.resistance &&
          !this.props.resultData.resultTypes.baseline
        ) {
          resistanceGraph = (
            <SegmentRecordResistanceRecordGraph
              sessionId={this.props.selectedSegmentRecordId}
              history={this.props.history}
            />
          );
        }
        if (
          this.props.resultData.resultTypes.fastFeet ||
          this.props.resultData.resultTypes.speedFocusedCardio
        ) {
          fastFeetGraph = (
            <SegmentRecordFastFeetGraph
              sessionId={this.props.selectedSegmentRecordId}
              history={this.props.history}
            />
          );
        }
        if (this.props.resultData.resultTypes.baseline) {
          baselineGraphs = (
            <SegmentRecordBaselineGraphs
              sessionId={this.props.selectedSegmentRecordId}
              history={this.props.history}
            />
          );
        }
      }
      display = (
        <React.Fragment>
          {cardioGraph}
          {resistanceGraph}
          {fastFeetGraph}
          {baselineGraphs}
        </React.Fragment>
      );
    }
    return (
      <div className="page-wrapper">
        <AppBar pageName="Workout Results" />
        <CommonSplitBanner
          leftComponent={
            <div>
              <div>Test:</div>
              {tests}
            </div>
          }
          rightComponent={
            <CommonStyledDropdown
              entities={[
                { name: "Graph", id: "graph" },
                { name: "Card", id: "card" },
              ]}
              defaultValue={this.state.displayType}
              onChange={this.setDisplayType}
              label="view"
            />
          }
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {display}
        </div>
        <CommonCenterBanner>
          <div>Athlete:</div>
          <div>{name}</div>
        </CommonCenterBanner>
        <SideNavigation
          leftLink={`/athlete-log/${athleteId}`}
          leftText="athlete log"
          rightLink={"/"}
          rightText="roster"
        />
        <UserFeedbackDrawer />
      </div>
    );
  }
}

//= =========================================
//		MAP STATE TO PROPS
//= =========================================

const mapStateToProps = (state, props) => {
  const searchParams = new URLSearchParams(props.location.search);
  const displayType = searchParams.get("display");
  const selectedSegmentRecordId = props.match.params.segmentRecordId;
  const resultData = getSegmentRecordResultData(state, selectedSegmentRecordId);
  const segmentRecord = getSegmentRecord(state, selectedSegmentRecordId);
  const cleanBaseURL = `${props.location.pathname}`;
  let session;
  if (segmentRecord) {
    session = getSession(state, segmentRecord.session_id);
  }
  let athlete;
  if (session) {
    athlete = state.athleteReducer.entities[session.user_id];
  }

  return {
    selectedSegmentRecordId,
    athlete,
    resultData,
    segmentRecord,
    session,
    displayType,
    cleanBaseURL,
  };
};

export default connect(mapStateToProps, {
  getSegmentRecordById,
  getSession,
  getSegmentRecordHeaderById,
  getNewAthleteById,
})(SegmentRecordDisplayPage);
