import { accountActionTypes } from "./account-reducer";

export function login(username, password) {
  return { type: accountActionTypes.login, username, password };
}

export function checkLogin() {
  return { type: accountActionTypes.checkLogin };
}

export function setLoginAttemptStatusMessage(message) {
  return {
    type: accountActionTypes.setLoginAttemptStatusMessage,
    message,
  };
}

export function setLoginAttemptStatusType(statusType) {
  return {
    type: accountActionTypes.setLoginAttemptStatusType,
    statusType,
  };
}

export function accountSignedIn(user) {
  return { type: accountActionTypes.accountSignedIn, user };
}

export function logout() {
  return { type: accountActionTypes.logOut };
}

export function loggedOut() {
  return { type: accountActionTypes.loggedOut };
}

export function selectOrganization(organizationId) {
  return { type: accountActionTypes.selectOrganization, organizationId };
}
