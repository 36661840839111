import { connect } from "react-redux";
import React from "react";
import Drawer from "../../common/drawer/common-drawer";
import { postUserFeedback, resetFeedbackState } from "../user-feedback-actions";

import "./user-feedback-drawer.scss";
import CommonButton from "../../common/button/common-button.tsx";

class UserFeedbackDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggleDrawer() {
    this.props.resetFeedbackState();
    this.setState({
      open: !this.state.open,
      userFeedback: "",
    });
  }

  handleInput(e) {
    this.setState({ userFeedback: e.target.value });
  }

  handleSubmit(e) {
    this.props.postUserFeedback(this.props.userId, this.state.userFeedback);
    e.preventDefault();
  }

  render() {
    let notification;
    let form = (
      <form onSubmit={this.handleSubmit}>
        <label
          className="user-feedback-drawer__label--visually-hidden"
          htmlFor="user-feedback-textarea"
        >
          User Feedback
        </label>
        <textarea
          onChange={this.handleInput}
          value={this.state.userFeedback}
          className="user-feedback-drawer__textarea"
          id="user-feedback-textrarea"
          autoFocus
          placeholder="Tell us what you think here"
        />
        <input style={{ display: "none" }} type="submit" value="Submit" />
        <div className="user-feedback-drawer__submit-button__container">
          <CommonButton modifiers={["red"]} onClick={this.handleSubmit}>
            Submit
          </CommonButton>
        </div>
      </form>
    );
    if (this.props.feedbackSent) {
      form = null;
      if (this.props.feedbackReceived) {
        notification = (
          <p className="user-feedback-drawer__notification user-feedback-drawer__notification--positive">
            We have received your feedback. Thank you!
          </p>
        );
      } else {
        if (this.props.feedbackError) {
          notification = (
            <p className="user-feedback-drawer__notification user-feedback-drawer__notification--negative">
              Sorry, there was an issue sending your feedback.
            </p>
          );
        } else {
          notification = (
            <p className="user-feedback-drawer__notification user-feedback-drawer__notification--neutral">
              Sending feedback...
            </p>
          );
        }
      }
    }
    return (
      <div className="user-feedback-drawer">
        <div
          className="user-feedback-drawer__fixed-toggle-button"
          onClick={this.toggleDrawer}
        >
          Send Feedback
        </div>
        <Drawer onClose={this.toggleDrawer} open={this.state.open}>
          <h1 className="user-feedback-drawer__h1">User Feedback</h1>
          <p className="user-feedback-drawer__p">
            User feedback helps us provide the best experience for everyone.
            Thank you for taking the time to tell us about what you like about
            the app, any features you would like to see, or any issues you have
            encountered.
          </p>
          {form}
          {notification}
          <div className="user-feedback-drawer__close-button__container">
            <CommonButton
              onClick={this.toggleDrawer}
              className="user-feedback-drawer__close-button"
            >
              Close
            </CommonButton>
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const userId = state.accountReducer.currentUserId;
  const feedbackSent = state.userFeedbackReducer.sent;
  const feedbackReceived = state.userFeedbackReducer.received;
  const feedbackError = state.userFeedbackReducer.error;
  return { userId, feedbackSent, feedbackReceived, feedbackError };
};

export default connect(mapStateToProps, {
  postUserFeedback,
  resetFeedbackState,
})(UserFeedbackDrawer);
