import React, { useContext } from "react";

import { FormContext } from "../form-context";
import SCAT5InputCommon from "../input/common/scat5-input-common";
import SCAT5InputRadio from "../input/radio/scat5-input-radio";
import ScrollToHereOnMount from "../../common/scroll-to-here-on-mount/scroll-to-here-on-mount";
import SCAT5InputTextarea from "../input/textarea/scat5-input-textarea";

const symptoms = [
  "headache",
  "pressureInHead",
  "neckPain",
  "nauseaOrVomiting",
  "dizziness",
  "blurredVision",
  "balanceProblems",
  "sensitivityToLight",
  "sensitivityToNoise",
  "feelingSlowedDown",
  "feelingLikeInAFog",
  "dontFeelRight",
  "difficultyConcentrating",
  "difficultyRemembering",
  "fatigueOrLowEnergy",
  "confusion",
  "drowsiness",
  "moreEmotional",
  "irritability",
  "sadness",
  "nervousOrAnxious",
  "troubleFallingAsleep",
];

const DecisionForm = (): JSX.Element => {
  const { values } = useContext(FormContext);

  const symptomValues = symptoms.map((symptom) => Number(values[symptom]) || 0);
  const symptomCount = symptomValues.filter((value) => value > 0).length;
  const symptomScore = symptomValues.reduce((a, b) => a + b, 0);

  const orientationValues = values["sacOrientation"] || {};

  const orientationScores = [
    orientationValues["knowsMonth"] == "true" ? 1 : 0,
    orientationValues["knowsDate"] == "true" ? 1 : 0,
    orientationValues["knowsDayOfWeek"] == "true" ? 1 : 0,
    orientationValues["knowsYear"] == "true" ? 1 : 0,
    orientationValues["knowsHourOfDay"] == "true" ? 1 : 0,
  ];
  const orientationSum = orientationScores.reduce((a, b) => a + b);

  const immediateMemoryValues = values["sacImmediateMemory"] || {};
  const immediateMemoryScore =
    Number(immediateMemoryValues.trial1) +
      Number(immediateMemoryValues.trial2) +
      Number(immediateMemoryValues.trial3) || 0;
  const maxMemoryScore =
    Number(immediateMemoryValues.selectedSACWordCount) * 3 || 0;

  const digitsBackwards = values["sacDigitsBackwards"];
  const monthsBackwards = values["sacMonthsInReverse"];
  const digitStrings = digitsBackwards
    ? {
        string0: digitsBackwards["numberString0"] == "true",
        string1: digitsBackwards["numberString1"] == "true",
        string2: digitsBackwards["numberString2"] == "true",
        string3: digitsBackwards["numberString3"] == "true",
        string4: digitsBackwards["numberString4"] == "true",
        string5: digitsBackwards["numberString5"] == "true",
        string6: digitsBackwards["numberString6"] == "true",
        string7: digitsBackwards["numberString7"] == "true",
      }
    : {};

  const digitScores = [
    digitStrings["string0"] && digitStrings["string1"] ? 1 : 0,
    digitStrings["string2"] && digitStrings["string3"] ? 1 : 0,
    digitStrings["string4"] && digitStrings["string5"] ? 1 : 0,
    digitStrings["string6"] && digitStrings["string7"] ? 1 : 0,
  ];

  const digitScore = digitScores.reduce((a, b) => a + b);
  const monthScore = monthsBackwards
    ? monthsBackwards["canListMonthsInReverse"] == "true"
      ? 1
      : 0
    : 0;
  const concentrationScore = digitScore + monthScore;

  const balanceScores = [
    Number(values["doubleLegStanceErrors"]) || 0,
    Number(values["singleLegStanceErrors"]) || 0,
    Number(values["tandemStanceErrors"]) || 0,
  ];

  const delayedRecallScore = Object.keys(
    values["accuratelyRecalledWords"] || {}
  ).length;
  const maxDelayedRecallScore =
    Number(immediateMemoryValues.selectedSACWordCount) || 0;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "2fr 2fr",
        width: "fit-content",
        margin: "0 auto",
        gap: "25px",
      }}
    >
      <ScrollToHereOnMount />
      <SCAT5InputCommon
        label="Date of assesment"
        id="dateOfAssesment"
        name="dateOfAssesment"
        type="date"
      />
      <SCAT5InputCommon
        label="Time of assesment"
        id="time-of-assesment"
        name="timeOfAssesment"
        type="time"
      />
      <div>Symptom Number</div>
      <div>{symptomCount} (of 22)</div>

      <div>Symptom Severity Score</div>
      <div>{symptomScore} (of 132)</div>

      <div>Orientation</div>
      <div>{orientationSum} (of 5)</div>

      <div>Immediate Memory</div>
      <div>
        {immediateMemoryScore} (of {maxMemoryScore})
      </div>

      <div>Concentration</div>
      <div>{concentrationScore} (of 5)</div>

      <SCAT5InputRadio
        label="Neuro exam"
        name="neuroExamDecision"
        modifiers={["horizontal"]}
        options={{ Normal: "normal", Abnormal: "abnormal" }}
      />

      <div>Balance Errors</div>
      <div>{balanceScores.reduce((a, b) => a + b)} (of 30)</div>

      <div>Delayed Recall</div>
      <div>
        {delayedRecallScore} (of {maxDelayedRecallScore})
      </div>

      <SCAT5InputCommon
        label="Date of injury"
        id="dateOfInjury"
        name="dateOfInjury"
        type="date"
      />

      <SCAT5InputRadio
        label="If the athlete is known to you prior to their injury, are they different from their usual self? (If different, describe why in the clinical notes section)"
        name="athleteDifferentFromUsualSelf"
        modifiers={["horizontal"]}
        options={{
          Yes: "yes",
          No: "no",
          Unsure: "unsure",
          "Not Applicable": "N/A",
        }}
      />

      <SCAT5InputRadio
        label="Concussion Diagnosed?"
        name="concussionDiagnosed"
        modifiers={["horizontal"]}
        options={{
          Yes: "yes",
          No: "no",
          Unsure: "unsure",
          "Not Applicable": "N/A",
        }}
      />

      <SCAT5InputRadio
        label="If re-testing, has the athlete improved?"
        name="retestingImprovment"
        modifiers={["horizontal"]}
        options={{
          Yes: "yes",
          No: "no",
          Unsure: "unsure",
          "Not Applicable": "N/A",
        }}
      />

      <h3 style={{ gridColumn: "1/3" }}>
        I am a physician or licensed healthcare professional and I have
        personally administered or supervised the administration of this SCAT5.
      </h3>

      <SCAT5InputCommon
        label="Signature"
        id="physician-signature"
        name="physicianSignature"
        type="text"
      />
      <SCAT5InputCommon
        label="Name"
        id="physician-name"
        name="physicianName"
        type="text"
      />
      <SCAT5InputCommon
        label="Title"
        id="physician-title"
        name="physicianTitle"
        type="text"
      />
      <SCAT5InputCommon
        label="Registration Number (if applicable)"
        id="physician-registration-number"
        name="physicianRegistrationNumber"
        type="text"
      />
      <SCAT5InputCommon
        label="Date"
        id="physician-signature-date"
        name="physicianSignatureDate"
        type="date"
      />
      <div
        style={{
          color: "white",
          backgroundColor: "#d2232a",
          borderRadius: "16px",
          padding: "5px 30px",
          gridColumn: "1/3",
        }}
      >
        <h3 style={{ textAlign: "center" }}>
          SCORING ON THE SCAT5 SHOULD NOT BE USED AS A STAND-ALONE METHOD TO
          DIAGNOSE CONCUSSION, MEASURE RECOVERY OR MAKE DECISIONS ABOUT AN
          ATHLETE’S READINESS TO RETURN TO COMPETITION AFTER CONCUSSION.
        </h3>
      </div>
      <h2>CLINICAL NOTES</h2>
      <SCAT5InputTextarea
        label="Clinical Notes"
        name="clinicalNotes"
        id="clinical-notes"
      />
    </div>
  );
};

export default DecisionForm;
