import React from "react";
import { connect } from "react-redux";
import { getAthletes } from "../../../selectors";
import CommonDateTimeDisplayField from "../../common/display-field/date-time/date-time";
import CommonList from "../../common/list/common-list";

class ResistanceComparisonList extends React.Component {
  render() {
    const baseline = this.props.baselineSegmentRecord;
    const baselineResult = {
      ...this.props.baselineResultData,
      date: (
        <CommonDateTimeDisplayField
          dateTime={baseline.segmentRecordStartTime}
        />
      ),
      athlete: this.props.baselineAthlete.name,
    };
    baselineResult.highlighted = true;
    const comparisons = this.props.comparisonSegmentRecords;
    const results = comparisons.map((comparison) => {
      let results = { ...comparison.results };
      results.date = (
        <CommonDateTimeDisplayField
          dateTime={comparison.segmentRecord.segmentRecordStartTime}
        />
      );
      results.athlete = comparison.athlete.name;
      return results;
    });
    results.unshift(baselineResult);
    let dataDisplayDefinitions = [
      { key: "date", display: "Date" },
      {
        key: "elapsedResistanceTime",
        display: "Time",
        style: { textAlign: "center" },
      },
      { key: "totalReps", display: "Reps", style: { textAlign: "center" } },
      {
        key: "exercises",
        display: "Exercises",
        style: { textAlign: "center" },
      },
      {
        key: "pounds",
        display: "Total Pounds",
        style: { textAlign: "center" },
      },
      {
        key: "maxResistanceHR",
        display: "Max HR",
        style: { textAlign: "center" },
      },
      {
        key: "averageResistanceHR",
        display: "Average HR",
        style: { textAlign: "center" },
      },
      {
        key: "minResistanceHR",
        display: "Min HR",
        style: { textAlign: "center" },
      },
    ];
    if (this.props.multipleAthletes) {
      dataDisplayDefinitions.unshift({
        key: "athlete",
        display: "Athlete",
        style: { textAlign: "center" },
      });
    }
    return (
      <CommonList
        entities={results}
        dataDisplayDefinitions={dataDisplayDefinitions}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const athletes = getAthletes(state);
  return { athletes };
};

export default connect(mapStateToProps, null)(ResistanceComparisonList);
