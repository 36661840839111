import { takeEvery, all, put } from "redux-saga/effects";
import { transcriberActionTypes } from "./transcriber-actions";
import { receiveTranscriptItems } from "./transcriber-actions";
import request from "../../my-axios.js";

//= ################################################
//				ENDPOINTS
//= ################################################

const ENDPOINTS = {
  sendRecording: () => `api/v1/store-audio`,
};

//= ################################################
//				WATCHER FUNCTIONS
//= ################################################

function* transcriberSagas(): Generator<unknown> {
  yield all([
    takeEvery(transcriberActionTypes.sendRecording, sendRecordingSaga),
  ]);
}

//= ################################################
//				WORKER FUNCTIONS
//= ################################################

function* sendRecordingSaga(action) {
  const formData = new FormData();
  formData.append("file", action.recording);
  formData.append("fileName", action.recording.name);
  formData.append("extension", ".mp4");
  formData.append("file_url", URL.createObjectURL(action.recording));

  // const player = new Audio(URL.createObjectURL(action.recording));
  // player.play();
  console.log("jkdlf", formData);
  try {
    const req = request.post(ENDPOINTS.sendRecording(), formData);
    const response = yield req;
    console.log("response", response);
    console.log("transcription results", response.data.transcript_items);
    const dataArray: number[] = [];
    response.data.transcript_items.map((item) => {
      if (item.type == "pronunciation") {
        const itemContent = item.alternatives[0];
        dataArray.push(itemContent.content);
      }
    });
    console.log("dataarray", dataArray);
    yield put(receiveTranscriptItems(dataArray));
  } catch (exception) {
    // window.testError = exception;
    console.log("exception", exception);
  }
}

export const sagas = { transcriberSagas };
