import React from "react";
import { connect } from "react-redux";

import AppBar from "../modules/common/app-bar/app-bar";
import OrganizationPicker from "../modules/organization/picker/organization-picker";
import AthleteRegistraionForm from "../modules/athlete/form/registration-form/athlete-registration-form";
import AthleteBulkImport from "../modules/athlete/bulk-import/athlete-bulk-import";
import CommonButton from "../modules/common/button/common-button";

const AddAthletePage = (props) => {
  const [displayType, setDisplayType] = React.useState("individual");

  const toggleDisplayType = () => {
    setDisplayType(displayType === "individual" ? "bulk" : "individual");
  };

  return (
    <React.Fragment>
      <AppBar pageName="Add Athlete" />
      <div style={{ margin: "0 auto 50px", width: "fit-content" }}>
        <OrganizationPicker />
      </div>
      <div style={{ width: "fit-content", margin: "0 auto 20px" }}>
        <CommonButton onClick={toggleDisplayType}>
          {displayType === "individual"
            ? "Switch to Bulk Import"
            : "Switch to Individaul Athlete"}
        </CommonButton>
      </div>
      {displayType === "individual" ? (
        <AthleteRegistraionForm
          selectedOrganizationId={props.organizationId}
          onSuccessRedirectTo={"/"}
          onCancel={() => props.history.push("/")}
        />
      ) : (
        <AthleteBulkImport organizationId={props.organizationId} />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const organizationId = state.accountReducer.selectedOrganization;
  return { organizationId };
};

export default connect(mapStateToProps, {})(AddAthletePage);
