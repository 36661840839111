import * as THREE from "three";
import { FontLoader } from "./FontLoader";
import { MarsdenBall } from "./objects/marsden-ball";

interface MarsdenBallExerciseParams {
  width: number;
  height: number;
  parentId?: string;
  fontSize?: number;
  radius?: number;
  numbersPerRow?: number;
  swingDecayTime?: number;
  rotationDecayTime?: number;
  seed?: string;
  list?: Array<number>;
}

interface Light {
  uuid: string;
  position?: THREE.Vector3;
}

const MarsdenBallExercise = ({
  width,
  height,
  parentId = "",
  fontSize = 2.2,
  radius = 10,
  numbersPerRow = 5,
  seed = "berriesANDcream",
  list,
}: MarsdenBallExerciseParams): void => {
  const x = 0;
  const y = 50;
  const z = -60;

  let font, scene, camera, renderer, ball;

  function animate(time = 0) {
    requestAnimationFrame(animate);
    // console.log("animate!", time);
    ball.update(time * 0.001);
    renderer.render(scene, camera);
  }

  function loadItems() {
    const loader = new FontLoader();
    loader.load(
      "https://assets.codepen.io/3685267/droid_sans_bold.typeface.json",
      function (fontx) {
        font = fontx;
        init();
        animate();
        // console.log("load?");
      }
    );
  }
  loadItems();

  function init() {
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);

    renderer = new THREE.WebGLRenderer();
    renderer.setSize(width, height);
    if (parentId) {
      document?.getElementById(parentId)?.appendChild(renderer.domElement);
    } else {
      document.body.appendChild(renderer.domElement);
    }

    ball = new MarsdenBall({
      scene,
      x,
      y,
      z,
      texture: null,
      font,
      fontSize,
      radius,
      numbersPerRow,
      seed,
      list,
    });

    addLights();

    window.addEventListener("resize", onWindowResize, false);
  }

  function onWindowResize() {
    const newWidth = Math.min(window.innerWidth, width);
    const newHeight = Math.min(window.innerHeight, height);
    camera.aspect = newWidth / newHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(newWidth, newHeight);
  }

  function addLights() {
    let color = 0xffffff;
    let intensity = 0.9;
    let light: Light = new THREE.DirectionalLight(color, intensity);
    light?.position?.set(0, 0, 80);
    scene.add(light);

    light?.position?.set(0, 0, 80);
    scene.add(light);

    color = 0x8899ff;
    intensity = 0.9;
    light = new THREE.DirectionalLight(color, intensity);
    light?.position?.set(50, 1000, -1000);
    scene.add(light);
  }
};

export { MarsdenBallExercise };
