import React from "react";

import SCAT5InputRadio from "../input/radio/scat5-input-radio";
import FormRadioSelectGridContainer from "../../form/radio-select/grid-container/form-radio-select-grid-container";
import ScrollToHereOnMount from "../../common/scroll-to-here-on-mount/scroll-to-here-on-mount";

const CervicalSpineAssessment = (): JSX.Element => {
  return (
    <React.Fragment>
      <ScrollToHereOnMount />
      <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>
        CERVICAL SPINE ASSESSMENT
      </h2>
      <FormRadioSelectGridContainer>
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Does the athlete report that their neck is pain free at rest?"
          name="neckPainFree"
          options={{
            Y: true,
            N: false,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="If there is NO neck pain at rest, does the athlete have a full
range of ACTIVE pain free movement?"
          name="neckFullRangeMotion"
          options={{
            Y: true,
            N: false,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Is the limb strength and sensation normal?"
          name="limbStrengthSensationNormal"
          options={{
            Y: true,
            N: false,
          }}
        />
      </FormRadioSelectGridContainer>
      <h1 style={{ textAlign: "center", color: "#d2232a" }}>
        In a patient who is not lucid or fully conscious, a cervical spine
        injury should be assumed until proven otherwise.
      </h1>
    </React.Fragment>
  );
};

export default CervicalSpineAssessment;
