import React from "react";
import { connect } from "react-redux";
import { logout } from "../account-actions";

class LogoutForm extends React.Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
  }

  logout() {
    // console.log("logout");
    this.props.logout();
    this.props.history.push("/login");
  }

  render() {
    return (
      <div>
        <button onClick={this.logout}>Logout</button>
      </div>
    );
  }
}

export default connect(null, { logout })(LogoutForm);
