import React from "react";

const RedFlags = (): JSX.Element => {
  return (
    <React.Fragment>
      <h2 style={{ textTransform: "uppercase", textAlign: "center", color: '#fff' }}>
        Step 1: Red Flags
      </h2>
      <div
        style={{
          color: "white",
          backgroundColor: "#d2232a",
          borderRadius: "16px",
          padding: "5px",
        }}
      >
        <h3 style={{ textAlign: "center", color: '#fff' }}>RED FLAGS</h3>
        <ul
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "5px",
          }}
        >
          <li style={{ gridColumn: "1/2", gridRow: "1/2" }}>
            Neck pain or tenderness
          </li>
          <li style={{ gridColumn: "1/2", gridRow: "2/3" }}>Double vision</li>
          <li style={{ gridColumn: "1/2", gridRow: "3/4" }}>
            Weakness or tingling/ burning in arms or legs
          </li>
          <li style={{ gridColumn: "1/2", gridRow: "4/5" }}>
            Severe or increasing headache
          </li>
          <li style={{ gridColumn: "1/2", gridRow: "5/6" }}>
            Seizure or convulsion
          </li>
          <li style={{ gridColumn: "2/3", gridRow: "1/2" }}>
            Loss of consciousness
          </li>
          <li style={{ gridColumn: "2/3", gridRow: "2/3" }}>
            Deteriorating conscious state
          </li>
          <li style={{ gridColumn: "2/3", gridRow: "3/4" }}>Vomiting</li>
          <li style={{ gridColumn: "2/3", gridRow: "4/5" }}>
            Increasingly restless, agitated or combative
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default RedFlags;
