import { createStore, combineReducers, applyMiddleware } from "redux";

import createSagaMiddleware from "redux-saga";

// =========IMPORT OUR REDUCERS
import athleteReducer from "./modules/athlete/athlete-reducer.js";
import accountReducer from "./modules/account/account-reducer.js";
import sessionReducer from "./modules/session/session-reducer.js";
import segmentRecordReducer from "./modules/segment-record/segment-record-reducer";
import machineReducer from "./modules/machine/machine-reducer.js";
import userFeedbackReducer from "./modules/user-feedback/user-feedback-reducer";
import transcriberReducer from "./modules/transcriber/transcriber-reducer.tsx";

// =========IMPORT OUR SAGAS
import { sagas as athleteSagas } from "./modules/athlete/athlete-sagas.js";
import { sagas as accountSagas } from "./modules/account/account-sagas.js";
import { sagas as sessionSagas } from "./modules/session/session-sagas.js";
import { sagas as segmentRecordSagas } from "./modules/segment-record/segment-record-sagas";
import { sagas as machineSagas } from "./modules/machine/machine-sagas.js";
import { sagas as userFeedbackSagas } from "./modules/user-feedback/user-feedback-sagas.js";
import { sagas as errorBoundarySagas } from "./modules/error-boundary/error-boundary-sagas";
import { sagas as transcriberSagas } from "./modules/transcriber/transcriber-sagas";

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  athleteReducer,
  accountReducer,
  sessionReducer,
  segmentRecordReducer,
  machineReducer,
  userFeedbackReducer,
  transcriberReducer,
});
const store = createStore(reducer, applyMiddleware(sagaMiddleware));

const sagas = {
  ...athleteSagas,
  ...accountSagas,
  ...sessionSagas,
  ...segmentRecordSagas,
  ...machineSagas,
  ...userFeedbackSagas,
  ...errorBoundarySagas,
  ...transcriberSagas,
};

for (const name in sagas) {
  sagaMiddleware.run(sagas[name]);
}

export default store;
