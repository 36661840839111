import React, { useContext } from "react";

import SCAT5InputRadio from "../../input/radio/scat5-input-radio";
import FormRadioSelectGridContainer from "../../../form/radio-select/grid-container/form-radio-select-grid-container";
import { FormContext, useFormContextValue } from "../../form-context";
import ScoreBox from "../../../form/score-box/score-box";

import "./sac-digits-backwards.scss";

const lists = {
  A: [
    "4-9-3",
    "6-2-9",
    "3-8-1-4",
    "3-2-7-9",
    "6-2-9-7-1",
    "1-5-2-8-6",
    "7-1-8-4-6-2",
    "5-3-9-1-4-8",
  ],
  B: [
    "5-2-6",
    "4-1-5",
    "1-7-9-5",
    "4-9-6-8",
    "4-8-5-2-7",
    "6-1-8-4-3",
    "8-3-1-9-6-4",
    "7-2-4-8-5-6",
  ],
  C: [
    "1-4-2",
    "6-5-8",
    "6-8-3-1",
    "3-4-8-1",
    "4-9-1-5-3",
    "6-8-2-5-1",
    "3-7-6-5-1-9",
    "9-2-6-5-1-4",
  ],
  D: [
    "7-8-2",
    "9-2-6",
    "4-1-8-3",
    "9-7-2-3",
    "1-7-9-2-6",
    "4-1-7-5-2",
    "2-6-4-8-1-7",
    "8-4-1-9-3-5",
  ],
  E: [
    "3-8-2",
    "5-1-8",
    "2-7-9-3",
    "2-1-6-9",
    "4-1-8-6-9",
    "9-4-1-7-5",
    "6-9-7-3-8-2",
    "4-2-7-9-3-8",
  ],
  F: [
    "2-7-1",
    "4-7-9",
    "1-6-8-3",
    "3-9-2-4",
    "2-4-7-5-8",
    "8-3-9-6-4",
    "5-8-6-2-4-9",
    "3-1-7-8-2-6",
  ],
};

const SACDigitsBackwards = (): JSX.Element => {
  const { values, setValue } = useContext(FormContext);
  const selectedList = values["selectedList"];

  const strings = {
    string0: useFormContextValue("numberString0") == "true",
    string1: useFormContextValue("numberString1") == "true",
    string2: useFormContextValue("numberString2") == "true",
    string3: useFormContextValue("numberString3") == "true",
    string4: useFormContextValue("numberString4") == "true",
    string5: useFormContextValue("numberString5") == "true",
    string6: useFormContextValue("numberString6") == "true",
    string7: useFormContextValue("numberString7") == "true",
  };

  const scores = [
    strings["string0"] && strings["string1"] ? 1 : 0,
    strings["string2"] && strings["string3"] ? 1 : 0,
    strings["string4"] && strings["string5"] ? 1 : 0,
    strings["string6"] && strings["string7"] ? 1 : 0,
  ];

  const selectList = (listName) => {
    setValue("selectedList", listName);
  };

  const listDisplay = (
    <div className="o-sacDigitsBackwards__listDisplay">
      {Object.keys(lists).map((listName) => {
        return (
          <ul
            key={`list-${listName}`}
            className="o-sacDigitsBackwards__listDisplay__list"
            onClick={() => selectList(listName)}
          >
            <h3>{listName}</h3>
            {lists[listName].map((item, index) => {
              return <li key={`list-item-${index}`}>{item}</li>;
            })}
          </ul>
        );
      })}
    </div>
  );
  const formDisplay = selectedList ? (
    <FormRadioSelectGridContainer>
      {lists[selectedList].map((label, index) => {
        return (
          <React.Fragment key={`row-${index}`}>
            <SCAT5InputRadio
              key={`form-item-${label}`}
              modifiers={["horizontal", "centerLabel"]}
              label={label}
              name={`numberString${index}`}
              options={{ Y: true, N: false }}
            />
            {index % 2 == 0 ? (
              <div
                className="o-sacDigitsBackwards__formDisplay__scoreCol"
                style={{ gridRow: `${index + 1}/${index + 3}` }}
              >
                {scores[index / 2]}
              </div>
            ) : null}
          </React.Fragment>
        );
      })}
      <div style={{ gridColumn: "1/4" }}>
        <ScoreBox label="Digits Score:">
          {scores.reduce((a, b) => a + b, 0)} of 4
        </ScoreBox>
      </div>
    </FormRadioSelectGridContainer>
  ) : null;

  return (
    <React.Fragment>{selectedList ? formDisplay : listDisplay}</React.Fragment>
  );
};
export default SACDigitsBackwards;
