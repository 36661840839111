import React from "react";

import "./form-rows.scss";

interface FormRowsProps {
  children: React.ReactNode;
}

const FormRows = ({ children }: FormRowsProps): JSX.Element => {
  return <div className="m-formRows">{children}</div>;
};

export default FormRows;
