import { takeEvery, all } from "redux-saga/effects";
import { errorBoundaryActionTypes } from "./error-boundary-actions";
import request from "../../my-axios.js";
import { SagaIterator } from "redux-saga";

//= ################################################
//				ENDPOINTS
//= ################################################

const ENDPOINTS = {
  reportError: (userId) => `api/v1/${userId}/roster-error-report`,
};

//= ################################################
//				WATCHER FUNCTIONS
//= ################################################

function* errorBoundarySagas(): SagaIterator {
  yield all([takeEvery(errorBoundaryActionTypes.reportError, reportErrorSaga)]);
}

//= ################################################
//				WORKER FUNCTIONS
//= ################################################

function* reportErrorSaga(action) {
  try {
    const data = {
      errorId: action.errorId,
      errorMessage: action.error.message,
      errorStack: action.error.stack,
      errorInfo: action.errorInfo,
    };

    const req = request.post(ENDPOINTS.reportError(action.userId), {
      errorData: data,
      errorId: action.errorId,
    });
    const response = yield req;
    if (response.data.success == true) {
      //   console.log("it worked");
    } else {
      //   console.log("it did not work");
    }
    console.log("ERROR RESPONSE", response);
  } catch (exception) {
    console.error(exception);
    // console.log("error boundary error", exception);
  }
}

export const sagas = { errorBoundarySagas };
