import React from "react";

import SCAT5InputRadio from "../input/radio/scat5-input-radio";
import FormRadioSelectGridContainer from "../../form/radio-select/grid-container/form-radio-select-grid-container";
import FormRows from "../../form/rows/form-rows";
import SCAT5InputCommon from "../input/common/scat5-input-common";
import SCAT5InputTextarea from "../input/textarea/scat5-input-textarea";
import ScrollToHereOnMount from "../../common/scroll-to-here-on-mount/scroll-to-here-on-mount";

const symptoms = [
  { label: "Headache", name: "headache" },
  { label: '"Pressure in head"', name: "pressureInHead" },
  { label: "Neck Pain", name: "neckPain" },
  { label: "Nausea or vomiting", name: "nauseaOrVomiting" },
  { label: "Dizziness", name: "dizziness" },
  { label: "Blurred vision", name: "blurredVision" },
  { label: "Balance problems", name: "balanceProblems" },
  { label: "Sensitivity to light", name: "sensitivityToLight" },
  { label: "Sensitivity to noise", name: "sensitivityToNoise" },
  { label: "Feeling slowed down", name: "feelingSlowedDown" },
  { label: 'Feeling like "in a fog"', name: "feelingLikeInAFog" },
  { label: '"Don’t feel right"', name: "dontFeelRight" },
  { label: "Difficulty concentrating", name: "difficultyConcentrating" },
  { label: "Difficulty remembering", name: "difficultyRemembering" },
  { label: "Fatigue or low energy", name: "fatigueOrLowEnergy" },
  { label: "Confusion", name: "confusion" },
  { label: "Drowsiness", name: "drowsiness" },
  { label: "More emotional", name: "moreEmotional" },
  { label: "Irritability", name: "irritability" },
  { label: "Sadness", name: "sadness" },
  { label: "Nervous or Anxious", name: "nervousOrAnxious" },
  {
    label: "Trouble falling asleep (if applicable)",
    name: "troubleFallingAsleep",
  },
];

const SymptomEvaluation = (): JSX.Element => {
  return (
    <React.Fragment>
      <ScrollToHereOnMount />
      <h2 style={{ textTransform: "uppercase", textAlign: "center" }}>
        STEP 2: SYMPTOM EVALUATION
      </h2>
      <p>
        The athlete should be
        <span style={{ color: "#d2232a" }}> given the symptom form </span>
        and asked to
        <span style={{ color: "#d2232a" }}>
          {""} read this instruction paragraph out loud {""}
        </span>
        then complete the symptom scale. For the baseline assessment, the
        athlete should rate his/her symptoms based on how he/she typically feels
        and for the post injury assessment the athlete should rate their
        symptoms at this point in time.
      </p>
      <SCAT5InputRadio
        modifiers={["horizontal"]}
        label="Please Check:"
        name="baselineOrPostInjury"
        options={{
          Baseline: "baseline",
          "Post-injury": "postInjury",
        }}
      />
      <h3 style={{ color: "#d2232a", textAlign: "center" }}>
        Please hand the form to the athlete
      </h3>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "2fr 3fr",
          columnGap: "9px",
        }}
      >
        <div></div>
        <div
          style={{
            display: "flex",
            gap: "15px",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <span>none</span> <span>mild</span> <span>moderate</span>
          <span>severe</span>
        </div>
        {symptoms.map((symptom) => {
          return (
            <SCAT5InputRadio
              key={`symptom-${symptom.name}`}
              modifiers={["horizontal"]}
              label={symptom.label}
              name={symptom.name}
              options={{
                0: "0",
                1: "1",
                2: "2",
                3: "3",
                4: "4",
                5: "5",
                6: "6",
              }}
            />
          );
        })}
      </div>
      <FormRows>
        <SCAT5InputCommon
          label="Total number of symptoms"
          id="reported-number-of-symptoms"
          name="reportedNumberOfSymptoms"
          type="number"
        />
        <div style={{ gridColumn: "3/3" }}>of 22</div>
        <SCAT5InputCommon
          label="Symptom severity score"
          id="reported-symptom-severity-score"
          name="reportedSymptomSeverityScore"
          type="number"
        />
        <div style={{ gridColumn: "3/3" }}>of 132</div>
      </FormRows>
      <FormRadioSelectGridContainer>
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Do your symptoms get worse with physical activity?"
          name="reportedSymptomsWorseWithPhysicalActivity"
          options={{
            Y: true,
            N: false,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Do your symptoms get worse with mental activity?"
          name="reportedSymptomsWorseWithMentalActivity"
          options={{
            Y: true,
            N: false,
          }}
        />
      </FormRadioSelectGridContainer>
      <FormRows>
        <SCAT5InputCommon
          label="If 100% is feeling perfectly normal, what percent of normal do you feel?"
          id="reported-percent-normal"
          name="reportedPercentNormal"
          type="number"
        />
        <SCAT5InputTextarea
          label="If not 100%, why?"
          id="reported-reason-not-100-percent"
          name="reportedReasonNot100Percent"
        />
      </FormRows>
      <h3 style={{ color: "#d2232a", textAlign: "center", marginTop: "20px" }}>
        Please hand form back to examiner
      </h3>
    </React.Fragment>
  );
};

export default SymptomEvaluation;
