import { connect } from "react-redux";
import React from "react";
import {
  clearFormSuccess,
  requestUserCode,
  clearGeneratedUserCode,
} from "../athlete-actions";
import { Redirect } from "react-router";

import CommonButton from "../../common/button/common-button.tsx";
import CommonFormInput from "../../common/form/input/common-form-input";

import "./athlete-form.scss";

class AthleteForm extends React.Component {
  constructor(props) {
    super(props);
    let {
      name = "",
      dob = "",
      weight = "",
      height = "",
      email = "",
      userCode = "",
    } = props;
    if (dob === null) {
      dob = "";
    }
    if (weight === null) {
      weight = "";
    }
    if (height === null) {
      height = "";
    }
    this.state = {
      name,
      dob,
      weight,
      height,
      email,
      password: "",
      passwordConfirm: "",
      userCode,
    };
    this.updateForm = this.updateForm.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.selectedOrganizationId != this.props.selectedOrganizationId) {
      this.props.requestUserCode(this.props.selectedOrganizationId);
    }

    const watchedProps = [
      "name",
      "dob",
      "weight",
      "height",
      "email",
      "password",
      "passwordConfirm",
      "userCode",
      "generatedUserCode",
    ];
    let stateUpdate = {};
    watchedProps.forEach((prop) => {
      if (oldProps[prop] != this.props[prop] && this.props[prop]) {
        stateUpdate[prop] = this.props[prop];
        if (prop == "generatedUserCode" && this.props[prop]) {
          stateUpdate.userCode = this.props[prop];
        }
      }
    });
    if (Object.keys(stateUpdate).length > 0) {
      this.setState(stateUpdate);
    }
  }

  updateForm(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (target.name == "userCode" && this.props.generatedUserCode) {
      this.props.clearGeneratedUserCode();
    }
    this.setState({
      [name]: value,
    });
  }

  cancel() {
    this.props.clearFormSuccess(this.props.formSubmissionId);
    this.props.onCancel();
  }

  componentDidMount() {
    if (!this.props.userCode && this.props.selectedOrganizationId) {
      this.props.requestUserCode(this.props.selectedOrganizationId);
    }
  }

  componentWillUnmount() {
    this.props.clearGeneratedUserCode();
  }

  render() {
    let errors = {};
    if (
      typeof this.props.formSubmissionState == "object" &&
      this.props.formSubmissionState != null
    ) {
      errors = this.props.formSubmissionState.errors;
    }
    const athleteData = {
      name: this.state.name,
      dob: this.state.dob,
      height: this.state.height,
      weight: this.state.weight,
      email: this.state.email,
      password: this.state.password,
      passwordConfirm: this.state.passwordConfirm,
      userCode: this.state.userCode,
    };

    let passwordFields;
    if (this.props.enablePasswordField) {
      passwordFields = (
        <React.Fragment>
          <CommonFormInput
            value={this.state.password}
            error={errors["athleteData.password"]}
            id="athlete-password"
            name="password"
            type="password"
            onChange={this.updateForm}
            label="Password"
          />

          <CommonFormInput
            value={this.state.passwordConfirm}
            error={errors["athleteData.passwordConfirm"]}
            id="athlete-password_confirm"
            name="passwordConfirm"
            type="password"
            onChange={this.updateForm}
            label="Confirm Password"
          />
        </React.Fragment>
      );
    }

    let buttons;

    if (this.props.formSubmissionState == "waiting") {
      buttons = <div>Submitting Data</div>;
    } else {
      buttons = (
        <React.Fragment>
          <div className={"athlete-form__buttons__cancel-container"}>
            <CommonButton onClick={this.cancel}>Cancel</CommonButton>
          </div>
          <div className={"athlete-form__buttons__submit-container"}>
            <CommonButton
              modifiers={["red"]}
              onClick={this.props.submitForm(athleteData)}
            >
              Submit
            </CommonButton>
          </div>
        </React.Fragment>
      );
    }

    let athleteForm = (
      <form
        className="athlete-form"
        onSubmit={this.props.submitForm(athleteData)}
      >
        <CommonFormInput
          value={this.state.name}
          error={errors["athleteData.name"]}
          id="athlete-name"
          name="name"
          type="text"
          onChange={this.updateForm}
          label="Name"
        />

        <CommonFormInput
          value={this.state.dob}
          error={errors["athleteData.dob"]}
          id="athlete-dob"
          name="dob"
          type="date"
          onChange={this.updateForm}
          label="Date of Birth"
        />

        <CommonFormInput
          value={this.state.weight}
          error={errors["athleteData.weight"]}
          id="athlete-weight"
          name="weight"
          type="number"
          onChange={this.updateForm}
          label="Weight (lbs)"
        />

        <CommonFormInput
          value={this.state.height}
          error={errors["athleteData.height"]}
          id="athlete-height"
          name="height"
          type="number"
          onChange={this.updateForm}
          label="Height (inches)"
        />

        <CommonFormInput
          value={this.state.email}
          error={errors["athleteData.email"]}
          id="athlete-email"
          name="email"
          type="email"
          onChange={this.updateForm}
          label="Email"
        />

        {passwordFields}

        <CommonFormInput
          value={this.state.userCode}
          error={errors["athleteData.userCode"]}
          id="athlete-conde"
          name="userCode"
          type="string"
          onChange={this.updateForm}
          label="User Code"
        />

        <div className={"athlete-form__buttons"}>{buttons}</div>
      </form>
    );

    if (this.props.formSubmissionState == "success") {
      athleteForm = (
        <div>
          <p>success!</p>
        </div>
      );
      window.setTimeout(() => {
        this.props.clearFormSuccess(this.props.formSubmissionId);
      }, 2000);
      if (this.props.onSuccessRedirectTo) {
        return <Redirect to={this.props.onSuccessRedirectTo} />;
      }
    }

    return athleteForm;
  }
}

const mapStateToProps = (state) => {
  const generatedUserCode = state.athleteReducer.generatedUserCode;
  return { generatedUserCode };
};

export default connect(mapStateToProps, {
  clearFormSuccess,
  requestUserCode,
  clearGeneratedUserCode,
})(AthleteForm);
