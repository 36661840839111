import React from "react";
import SegmentRecordResultsGraph from "../results-graph";

class SegmentRecordFastFeetResultsGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sortSegmentRecordsByDate(segmentRecords) {
    // console.log("%cSorting", "background:orange;");
    const sortedSegmentRecords = segmentRecords.slice().sort((b, a) => {
      if (a.segmentRecord && b.segmentRecord) {
        return (
          new Date(b.segmentRecord.segmentRecordStartTime).getTime() -
          new Date(a.segmentRecord.segmentRecordStartTime).getTime()
        );
      }
      return 0;
    });
    return sortedSegmentRecords;
  }

  getLineDatum(comparisonSegmentRecord, selectedStat) {
    let datum = {
      x: new Date(
        comparisonSegmentRecord.segmentRecord.segmentRecordStartTime
      ).toString(),
    };
    datum["y"] = comparisonSegmentRecord.results[selectedStat];
    return datum;
  }

  getLineData(segmentRecords, selectedStat) {
    return segmentRecords.map((comparisonSegmentRecord) =>
      this.getLineDatum(comparisonSegmentRecord, selectedStat)
    );
  }

  render() {
    const allSegmentRecords = [
      ...this.props.comparisonSegmentRecords,
      {
        id: this.props.baselineSegmentRecord.id,
        segmentRecord: this.props.baselineSegmentRecord,
        results: this.props.baselineResultData,
      },
    ];
    // console.log(allSegmentRecords);
    const fastFeetSegmentRecords = this.sortSegmentRecordsByDate(
      allSegmentRecords.filter(
        (segmentRecord) => segmentRecord.results.resultTypes.fastFeet
      )
    );
    const lines = {
      elapsedFastFeetTime: {
        data: this.getLineData(fastFeetSegmentRecords, "elapsedFastFeetTime"),
        color: "#796FF3",
        duration: 2000,
        label: "Elapsed Speed Steps Time",
      },
      fastFeetScore: {
        data: this.getLineData(fastFeetSegmentRecords, "fastFeetScore"),
        color: "#F3BE6F",
        duration: 1000,
        label: "Speed Steps Score",
      },
      averageFastFeetHR: {
        data: this.getLineData(fastFeetSegmentRecords, "averageFastFeetHR"),
        color: "#d2232a",
        duration: 4000,
        label: "Average Heart Rate",
      },
    };
    const xAxis = fastFeetSegmentRecords.map((comparisonSegmentRecord) => {
      return {
        id: comparisonSegmentRecord.segmentRecord.id,
        date: new Date(
          comparisonSegmentRecord.segmentRecord.segmentRecordStartTime
        ).toString(),
      };
    });
    return (
      <SegmentRecordResultsGraph
        lines={lines}
        chartLabel="Speed Steps Results"
        linkPage="segment-record"
        xAxis={xAxis}
        history={this.props.history}
        baselineSegmentRecord={this.props.baselineSegmentRecord}
        baselineResultData={this.props.baselineResultData}
        multipleAthletes={this.props.multipleAthletes}
        comparisonSegmentRecords={this.props.comparisonSegmentRecords}
      />
    );
  }
}

export default SegmentRecordFastFeetResultsGraph;
