import { ErrorInfo } from "react";

export const errorBoundaryActionTypes = {
  reportError: "REPORT_ERROR",
};

export function reportError(
  errorId: string,
  userId: string,
  error: Error,
  errorInfo: ErrorInfo
): Record<string, unknown> {
  return {
    type: errorBoundaryActionTypes.reportError,
    errorId,
    userId,
    error,
    errorInfo,
  };
}
