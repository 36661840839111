import React, { useContext } from "react";

import { FormContext } from "../form-context";

import SCAT5InputCommon from "../input/common/scat5-input-common";
import SCAT5InputTextarea from "../input/textarea/scat5-input-textarea";
import FormRows from "../../form/rows/form-rows";

const PatientDetailsForm = (): JSX.Element => {
  const { athlete, trainer } = useContext(FormContext);
  const date = new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const injuryDate = year + "-" + month + "-" + day;
  // console.log("athelre", athlete, injuryDate);
  return (
    <React.Fragment>
      <h2 style={{ textAlign: "center", textTransform: "uppercase", color: '#fff' }}>
        Patient Details
      </h2>
      <FormRows>
        <SCAT5InputCommon
          label="Name"
          id="patient-name"
          name="patientName"
          type="text"
          value={athlete ? athlete.name : ""}
        />
        <SCAT5InputCommon
          label="DOB"
          id="patient-dob"
          name="patientDOB"
          type="date"
          value={athlete ? athlete.dob : ""}
        />
        {/* <SCAT5InputTextarea
          label="Address"
          id="patient-address"
          name="patientAddress"
          type="text"
        /> */}
             <SCAT5InputCommon
          label="Address"
          id="patient-address"
          name="patientAddress"
          type="date"
          // value={athlete ? athlete.address : ""}
        />
        
        <SCAT5InputCommon
          label="ID number"
          id="patient-id-number"
          name="patientIdNumber"
          type="text"
          value={athlete ? athlete.id : ""}
        />
        <SCAT5InputCommon
          label="Examiner"
          id="patient-examiner"
          name="patientExaminer"
          type="text"
          value={trainer ? trainer : ""}
        />
        <SCAT5InputCommon
          label="Date of Injury"
          id="patient-date-of-injury"
          name="dateOfInjury"
          type="date"
        />
        <SCAT5InputCommon
          label="Time of Injury"
          id="patient-time-of-injury"
          name="patientTimeOfInjury"
          type="time"
        />
      </FormRows>
    </React.Fragment>
  );
};

export default PatientDetailsForm;
