import React from "react";
import SegmentRecordResultsGraph from "../results-graph";

class SegmentRecordBaselineResultsGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sortSegmentRecordsByDate(segmentRecords) {
    // console.log("%cSorting", "background:orange;");
    const sortedSegmentRecords = segmentRecords.slice().sort((b, a) => {
      if (a.segmentRecord && b.segmentRecord) {
        return (
          new Date(b.segmentRecord.segmentRecordStartTime).getTime() -
          new Date(a.segmentRecord.segmentRecordStartTime).getTime()
        );
      }
      return 0;
    });
    return sortedSegmentRecords;
  }

  getSubsetBaselineLineData(segmentRecords, selectedStat, subSelection) {
    return segmentRecords.map((comparisonSegmentRecord) =>
      this.getSubsetBaselineLineDatum(
        comparisonSegmentRecord,
        selectedStat,
        subSelection
      )
    );
  }

  getSubsetBaselineLineDatum(
    comparisonSegmentRecord,
    selectedStat,
    subSelection
  ) {
    let datum = {
      x: new Date(
        comparisonSegmentRecord.segmentRecord.segmentRecordStartTime
      ).toString(),
    };
    datum["y"] =
      comparisonSegmentRecord.results.baselineResults[selectedStat][
        subSelection
      ];
    return datum;
  }

  getBaselineLineDatum(comparisonSegmentRecord, selectedStat) {
    let datum = {
      x: new Date(
        comparisonSegmentRecord.segmentRecord.segmentRecordStartTime
      ).toString(),
    };
    datum["y"] = comparisonSegmentRecord.results.baselineResults[selectedStat];
    return datum;
  }

  getBaselineLineData(segmentRecords, selectedStat) {
    return segmentRecords.map((comparisonSegmentRecord) =>
      this.getBaselineLineDatum(comparisonSegmentRecord, selectedStat)
    );
  }

  render() {
    const graphs = {
      warmUpCardio: {
        lines: [],
        xAxis: [],
        chartLabel: "Warm-up Cardio",
        type: "JUMP",
      },
      warmUp: {
        lines: [],
        xAxis: [],
        chartLabel: "Warm-up Resistance",
        type: "RESISTANCE",
      },
      speedSteps: {
        lines: [],
        xAxis: [],
        chartLabel: "Speed Steps",
        type: "JUMP",
      },
      torsoRotation: {
        lines: [],
        xAxis: [],
        chartLabel: "Torso Rotation",
        type: "RESISTANCE",
      },
      singleJump: {
        lines: [],
        xAxis: [],
        chartLabel: "Single Leg Jump",
        type: "JUMP",
      },
      chestFly: {
        lines: [],
        xAxis: [],
        chartLabel: "Chest Fly",
        type: "RESISTANCE",
      },
      warmDown: {
        lines: [],
        xAxis: [],
        chartLabel: "Warm-down Cardio",
        type: "JUMP",
      },
    };
    const allSegmentRecords = [
      ...this.props.comparisonSegmentRecords,
      {
        id: this.props.baselineSegmentRecord.id,
        segmentRecord: this.props.baselineSegmentRecord,
        results: this.props.baselineResultData,
      },
    ];
    // console.log(allSegmentRecords);
    const baselineSegmentRecords = this.sortSegmentRecordsByDate(
      allSegmentRecords.filter(
        (segmentRecord) => segmentRecord.results.resultTypes.baseline
      )
    );
    let lines,
      xAxis = [];
    Object.keys(graphs).forEach((graphName) => {
      if (graphs[graphName].type == "JUMP") {
        lines = {
          jumps: {
            data: this.getBaselineLineData(
              baselineSegmentRecords,
              `${graphName}Jumps`
            ),
            color: "#F3BE6F",
            duration: 1000,
            label: "Jumps",
          },
        };
      } else if (graphs[graphName].type == "RESISTANCE") {
        lines = {
          totalReps: {
            data: this.getSubsetBaselineLineData(
              baselineSegmentRecords,
              `${graphName}Reps`,
              "totalReps"
            ),
            color: "#F3BE6F",
            duration: 4000,
            label: "Total Reps",
          },
          totalPounds: {
            data: this.getSubsetBaselineLineData(
              baselineSegmentRecords,
              `${graphName}Reps`,
              "totalPounds"
            ),
            color: "#03BE6F",
            duration: 4000,
            label: "Total Pounds",
          },
        };
      }
      lines.avgHR = {
        data: this.getSubsetBaselineLineData(
          baselineSegmentRecords,
          graphName == "warmUp"
            ? "warmUpResistanceHRData"
            : `${graphName}HRData`,
          "avgHR"
        ),
        color: "#d2232a",
        duration: 4000,
        label: "Average Heart Rate",
      };
      xAxis = baselineSegmentRecords.map((comparisonSegmentRecord) => {
        return {
          id: comparisonSegmentRecord.segmentRecord.id,
          date: new Date(
            comparisonSegmentRecord.segmentRecord.segmentRecordStartTime
          ).toString(),
        };
      });
      graphs[graphName].lines = lines;
      graphs[graphName].xAxis = xAxis;
    });
    return Object.keys(graphs).map((graphName) => {
      const graph = graphs[graphName];
      return (
        <SegmentRecordResultsGraph
          key={`${graphName}--graph`}
          lines={graph.lines}
          chartLabel={graph.chartLabel}
          linkPage="segment-record"
          xAxis={graph.xAxis}
          history={this.props.history}
          baselineSegmentRecord={this.props.baselineSegmentRecord}
          baselineResultData={this.props.baselineResultData}
          multipleAthletes={this.props.multipleAthletes}
          comparisonSegmentRecords={this.props.comparisonSegmentRecords}
        />
      );
    });
  }
}

export default SegmentRecordBaselineResultsGraph;
