import { normalize } from "normalizr";
import { arrayWrap } from "../../easy-reducer.js";
import { athleteSchema } from "../../normalizr-schema.js";
import { athleteActionTypes } from "./athlete-reducer";
import { normalizedDataReceived } from "../app/app-actions.js";

export function athleteReceived(athlete) {
  const athletes = normalize(arrayWrap(athlete), [athleteSchema]);
  return normalizedDataReceived(athletes.entities);
}

export function clearAthletes() {
  return { type: athleteActionTypes.clearAthletes };
}

export function clearFormSuccess(id) {
  return {
    type: athleteActionTypes.clearFormSuccess,
    id,
  };
}

export function editAthlete(id, athleteId, organizationId, athleteData) {
  return {
    id,
    athleteId,
    type: athleteActionTypes.editAthlete,
    data: { organizationId, athleteData },
  };
}
export function registerNewAthlete(id, organizationId, athleteData) {
  return {
    id,
    type: athleteActionTypes.registerNewAthlete,
    data: { organizationId, athleteData },
  };
}

export function registerNewAthletesInBulk(id, organizationId, athletesData) {
  return {
    id,
    type: athleteActionTypes.registerNewAthletesInBulk,
    data: { organizationId, athletesData },
  };
}

export function athleteRegistrationRequestSent(id) {
  return { type: athleteActionTypes.athleteRegistrationReqSent, id };
}

export function athleteRegistered(id, errors = null, athleteData = null) {
  return {
    type: athleteActionTypes.athleteRegistered,
    id,
    errors,
    athleteData,
  };
}

export function registrationAck(id) {
  return {
    type: athleteActionTypes.registrationACK,
    id,
  };
}

export function getAllAthletesForTrainer(trainerId) {
  // console.log("get all action");
  return {
    type: athleteActionTypes.getAll,
    trainerId,
  };
}

export function getAthleteById(id, organizationId) {
  // console.log("get by id action", id, organizationId);
  return {
    type: athleteActionTypes.getAthleteById,
    id,
    organizationId,
  };
}

export function getNewAthleteById(id) {
  return {
    type: athleteActionTypes.getNewAthleteById,
    id,
  };
}

export function requestUserCode(organizationId) {
  return { type: athleteActionTypes.requestUserCode, organizationId };
}

export function clearGeneratedUserCode() {
  return { type: athleteActionTypes.clearGeneratedUserCode };
}

export function userCodeReceived(userCode) {
  return { type: athleteActionTypes.userCodeReceived, userCode };
}
