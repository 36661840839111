import * as THREE from "three";
import { TextGeometry } from "../TextGeometry";
class MarsdenBall {
  constructor({
    scene,
    x,
    y,
    z = 0,
    radius,
    texture,
    font,
    fontSize,
    numbersPerRow,
    swingDecayTime = 0,
    rotationDecayTime = 0,
    seed,
    list,
  }) {
    this.scene = scene;
    this.phase = Math.random() * 20.0;
    const base = new THREE.Group();
    base.position.x = x;
    base.position.y = y;

    this.base = base;
    scene.add(base);
    this.length = -50; // - rand(10);
    //    this.swingOffset = rand(10);
    const item = new THREE.Group();
    item.position.y = this.length;
    base.add(item);
    this.item = item;
    this.texture = texture;

    this.radius = radius;

    this.text = 1;

    this.font = font;
    this.fontSize = fontSize;
    this.numbersPerRow = numbersPerRow;
    this.numbers = list;
    this.seed = seed;
    // this.generateNumbers(seed);

    this.swingDecayEnabled = swingDecayTime > 0 ? true : false;
    this.rotationDecayEnabled = rotationDecayTime > 0 ? true : false;
    this.swingDecayTime = swingDecayTime;
    this.rotationDecayTime = rotationDecayTime;

    this.material = new THREE.MeshPhongMaterial({
      //   map: this.texture,
      color: "#880000",
      shininess: 40,
      // opacity:0.5
    });
    this.fontMaterial = new THREE.MeshPhongMaterial({
      //   map: this.texture,
      color: "#ffffff",
      shininess: 120,
    });
    this.imageMaterial = new THREE.MeshPhongMaterial({
      color: "#ffffff",
      shininess: 120,
    });
    this.addItems();

    base.position.z = z;
  }
  addItems() {
    this.addBall();
    this.addLine();
    this.addCylynder();
    this.addRing();
    this.addText();
    this.addImages();
  }
  addBall() {
    const geometry = new THREE.SphereBufferGeometry(this.radius, 20, 20);

    const ball = new THREE.Mesh(geometry, this.material);
    this.item.add(ball);
  }
  addCylynder() {
    const geometry = new THREE.CylinderBufferGeometry(0.8, 0.8, 2, 15, 5);

    const mesh = new THREE.Mesh(geometry, this.material);
    mesh.position.x = 0;
    mesh.position.y = this.radius - 0.75;
    this.item.add(mesh);
  }
  addRing() {
    const geometry = new THREE.TorusBufferGeometry(0.8, 0.2, 10, 24);
    const material = new THREE.MeshPhongMaterial({
      color: 0x393e46,
      shininess: 80,
    });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.x = 0;
    mesh.position.y = this.radius + 0.45;
    this.item.add(mesh);
  }
  addLine() {
    const material = new THREE.LineBasicMaterial({
      color: 0x666666,
    });
    const points = [
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, this.length + 6, 0),
    ];
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const line = new THREE.Line(geometry, material);

    this.base.add(line);
  }

  //These can probably be combined into one parameterized function
  // also may want to explore caclulating a lookup table ahead
  // of time for performance
  calculateSwingAngle(time) {
    const sineSwing = Math.sin(time * 1.5 + this.phase);
    const shapingSwing = (Math.exp(Math.abs(sineSwing)) - 1) / 2.2;
    const angleSwing = sineSwing - 0.1 * shapingSwing * Math.sign(sineSwing);
    const decay = this.swingDecayEnabled
      ? angleSwing * (1 / this.swingDecayTime) * time
      : 0;
    // console.log("swing angle!", angleSwing - decay);
    return angleSwing - decay;
  }

  calulateRotationAngle(time) {
    //https://graphtoy.com/?f1(x,t)=sin((t+x)/3)*3&v1=true&f2(x,t)=(exp(abs(f1(x,t)))-1)/2.2&v2=true&f3(x,t)=(f1(x,t)-(0.1*f2(x,t)*sign(f1(x,t))))*3&v3=true&f4(x,t)=30&v4=true&f5(x,t)=f3(x,t)-f3(x,t)*(1/f4())*min(t,f4())&v5=true&f6(x,t)=&v6=false&grid=true&coords=0,0,12
    const sineRotation = Math.sin((time + this.phase) / 1.5) * 2;
    const shapingRotation = (Math.exp(Math.abs(sineRotation)) - 1) / 2.2;
    const angleRotation =
      (sineRotation - 0.1 * shapingRotation * Math.sign(sineRotation)) * 3;
    const decay = this.rotationDecayEnabled
      ? angleRotation * (1 / this.rotationDecayTime) * time
      : (3 * sineRotation) / 2;
    // console.log("rotation angle!", angleRotation - decay);
    return angleRotation - decay;
  }

  update(time) {
    //const angle = map(Math.sin(time + this.phase ), -1, 1, -rad(45), rad(45));
    //this.item.rotation.y = angle * 3;
    //this.base.rotation.z = angle;
    //   console.log('time',time);
    // console.log("update!", time);
    if (!this.swingDecayEnabled || time <= this.swingDecayTime) {
      this.base.rotation.z = this.calculateSwingAngle(time);
    }

    if (!this.rotationDecayEnabled || time <= this.rotationDecayTime) {
      this.item.rotation.y = this.calulateRotationAngle(time);
    }
  }

  // generateNumbers(seed) {
  //   const rowCount = Math.round(this.radius / this.fontSize);
  //   const numberCount = rowCount * this.numbersPerRow;
  //   const list = seededRandomNumberList(seed, numberCount);
  //   this.numbers = list;
  //   //   console.log("list", list);
  // }

  addText() {
    let rowCount = Math.round(this.radius / this.fontSize);
    for (let row = 0; row < rowCount; row++) {
      let numberCount = this.numbersPerRow;
      for (let i = 0; i < numberCount; i++) {
        const number = this.numbers[row * numberCount + i];
        const text = "" + number + "";
        // console.log("MARSDEN", number, text);
        const geometry = new TextGeometry(text, {
          font: this.font,
          size: this.fontSize,
          height: 0.4,
          curveSegments: 12,
        });
        const mesh = new THREE.Mesh(geometry, this.fontMaterial);
        // console.log("mesh", mesh);
        const period =
          i * ((Math.PI * 2.0) / numberCount) + Math.PI * ((row % 2) + 1);
        mesh.position.y =
          ((2 * this.radius) / (rowCount + 1)) * (row + 1) -
          this.radius -
          this.fontSize / 2;
        const radiusAtY = Math.sqrt(
          Math.pow(this.radius, 2) - Math.pow(mesh.position.y, 2)
        );
        mesh.position.z = Math.cos(period) * radiusAtY;
        mesh.position.x = Math.sin(period) * radiusAtY;
        // console.log("number position", mesh.position);
        // rotate it
        const centerVector = new THREE.Vector3(0, 0, 0);
        centerVector.sub(mesh.position);
        centerVector.normalize();
        mesh.lookAt(centerVector);
        this.item.add(mesh);
        // console.log("center vector", centerVector, mesh.lookAt, this.item);
      }
    }
  }

  addImages() {
    //   // Create a texture loader so we can load our image file
    //   const textureLoader = new THREE.TextureLoader();
    //   // Load an image file into a custom material
    //   const image = new THREE.MeshPhongMaterial({
    //     map: textureLoader.load(
    //       "https://s3.amazonaws.com/duhaime/blog/tsne-webgl/assets/cat.jpg"
    //     ),
    //   });
    //   console.log("material!", image);
    //   const geometry = new THREE.PlaneGeometry(5, 5);
    //   const mesh = new THREE.Mesh(geometry, image);
    //   mesh.position.y = 1;
    //   mesh.position.x = 1;
    //   mesh.position.z = 10;
    //   this.item.add(mesh);
    //   let rowCount = Math.round(this.radius / this.fontSize);
    //   for (let row = 0; row < rowCount; row++) {
    //     let numberCount = this.numbersPerRow;
    //     for (let i = 0; i < numberCount; i++) {
    //       const geometry = new THREE.PlaneGeometry(10, 10);
    //       const mesh = new THREE.Mesh(geometry, image);
    //       const period =
    //         i * ((Math.PI * 2.0) / numberCount) + Math.PI * ((row % 2) + 1);
    //       mesh.position.y =
    //         ((2 * this.radius) / (rowCount + 1)) * (row + 1) -
    //         this.radius -
    //         this.fontSize / 2;
    //       const radiusAtY = Math.sqrt(
    //         Math.pow(this.radius, 2) - Math.pow(mesh.position.y, 2)
    //       );
    //       mesh.position.z = Math.cos(period) * radiusAtY;
    //       mesh.position.x = Math.sin(period) * radiusAtY;
    //       console.log("position", mesh.position, period);
    //       // rotate it
    //       const centerVector = new THREE.Vector3(0, 0, 0);
    //       centerVector.sub(mesh.position);
    //       centerVector.normalize();
    //       mesh.lookAt(centerVector);
    //       this.item.add(mesh);
    //     }
    //   }
  }
}

export { MarsdenBall };
