import React, { useState } from "react";
import { connect } from "react-redux";

import { registerNewAthletesInBulk } from "../athlete-actions";

import AthleteBulkImportCSV from "./csv/athlete-bulk-import-csv";
import CommonList from "../../common/list/common-list";
import CommonButton from "../../common/button/common-button";
import { useEffect } from "react";

import "./athlete-bulk-import.scss";

interface AthleteBulkImportProps {
  organizationId: string;
  registrationState: any;
  registerNewAthletesInBulk: (
    id: string,
    organizationId: string,
    athleteData: Array<Record<string, any>>
  ) => void;
}

const dataDisplayDefinitions = [
  { key: "name", display: "Name" },
  { key: "email", display: "Email", style: { wordBreak: "break-word" } },
  { key: "dob", display: "Date of Birth" },
  { key: "userCode", display: "User Code" },
  { key: "weight", display: "Weight" },
  { key: "height", display: "Height" },
];

//useEffect(() => {
//  console.log("registrationState", registrationState);
//}, [registrationState]);

const AthleteBulkImport = ({
  organizationId,
  registrationState,
  registerNewAthletesInBulk,
}: AthleteBulkImportProps): JSX.Element => {
  const [athleteData, setAthleteData] = useState([]);
  const [msg, setMsg] = useState<React.ReactNode>(null);

  useEffect(() => {
    if (typeof registrationState === "string") {
      setMsg(
        <div className="o-athleteBulkImport__message">{registrationState}</div>
      );
    } else {
      if (registrationState && registrationState.errors) {
        const errorKeys = Object.keys(registrationState.errors);
        const errors = errorKeys.map((key) => {
          const [, row, field] = key.split(".");
          return (
            <div
              key={`error-${key}`}
              className="o-athleteBulkImport__message__error"
            >
              Error in {field} field on line {row}:{" "}
              {registrationState.errors[key]}
            </div>
          );
        });
        setMsg(<div className="o-athleteBulkImport__message">{errors}</div>);
      }
    }
  }, [registrationState]);

  // console.log("registrationState", registrationState);

  const submit = (e) => {
    e.preventDefault();
    const athletesData = athleteData.map((athlete) => {
      const date = new Date(Date.parse(athlete.dob));
      const formattedDate = date
        ? `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
        : null;
      // console.log(date);
      // console.log(formattedDate);
      return {
        ...athlete,
        dob: formattedDate || "",
        weight: athlete.weight || "",
        height: athlete.height || "",
        userCode: athlete.userCode || "",
      };
    });
    registerNewAthletesInBulk("bulk-athlete", organizationId, athletesData);
  };

  return (
    <div className="o-athleteBulkImport">
      <AthleteBulkImportCSV onData={setAthleteData} />
      {msg}
      {athleteData.length > 0 &&
      registrationState !== "waiting" &&
      registrationState != "success" ? (
        <React.Fragment>
          <CommonButton modifiers={[]} onClick={submit}>
            Register
          </CommonButton>
          <CommonList
            entities={athleteData}
            dataDisplayDefinitions={dataDisplayDefinitions}
          />
        </React.Fragment>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  const registrationState =
    state.athleteReducer.formSubmissionState["bulk-athlete"];
  return { registrationState };
};

export default connect(mapStateToProps, { registerNewAthletesInBulk })(
  AthleteBulkImport
);
