import React from "react";
import { registerNewAthlete } from "../../athlete-actions";
import { connect } from "react-redux";
import AthleteForm from "../athlete-form";

class AthleteRegistraionForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(athleteData) {
    return (e) => {
      e.preventDefault();
      this.props.registerNewAthlete(
        "registerNewAthlete",
        this.props.selectedOrganizationId,
        athleteData
      );
    };
  }

  render() {
    let athleteData = {
      name: "",
      dob: "1980-01-01",
      weight: "",
      height: "",
      email: "",
      password: "",
      userCode: "",
    };
    if (this.props.registrationState) {
      athleteData = this.props.registrationState.submittedData;
    }

    return (
      <AthleteForm
        {...athleteData}
        selectedOrganizationId={this.props.selectedOrganizationId}
        enablePasswordField={true}
        formSubmissionState={this.props.registrationState}
        formSubmissionId="registerNewAthlete"
        onSuccessRedirectTo={this.props.onSuccessRedirectTo}
        onCancel={this.props.onCancel}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = (state) => {
  let trainerId = state.accountReducer.currentUserId;
  let registrationState =
    state.athleteReducer.formSubmissionState["registerNewAthlete"];
  return { trainerId, registrationState };
};

export default connect(mapStateToProps, { registerNewAthlete })(
  AthleteRegistraionForm
);
