import React from "react";
import { connect } from "react-redux";
import { getAthletes } from "../../../selectors";
import CommonList from "../../common/list/common-list";
import CommonDateTimeDisplayField from "../../common/display-field/date-time/date-time";

class BaselineComparisonList extends React.Component {
  render() {
    const baseline = this.props.baselineSegmentRecord;
    const baselineResult = {
      ...this.props.baselineResultData,
      date: (
        <CommonDateTimeDisplayField
          dateTime={baseline.segmentRecordStartTime}
        />
      ),
      athlete: this.props.baselineAthlete.name,
    };
    baselineResult.highlighted = true;
    const comparisons = this.props.comparisonSegmentRecords;
    const results = comparisons.map((comparison) => {
      let results = { ...comparison.results };
      results.date = (
        <CommonDateTimeDisplayField
          dateTime={comparison.segmentRecord.segmentRecordStartTime}
        />
      );
      results.athlete = comparison.athlete.name;
      return results;
    });
    results.unshift(baselineResult);
    let dataDisplayDefinitions = [
      { key: "date", display: "Date" },
      {
        key: "baselineResults",
        subKeys: ["warmUpCardioJumps"],
        display: "Warm-up Jumps",
        style: { textAlign: "center" },
      },
      {
        key: "baselineResults",
        subKeys: ["warmUpReps", "totalPounds"],
        display: "Warm-up Pounds",
        style: { textAlign: "center" },
      },
      {
        key: "baselineResults",
        subKeys: ["torsoRotationReps", "totalPounds"],
        display: "Torso Pounds",
        style: { textAlign: "center" },
      },
      {
        key: "baselineResults",
        subKeys: ["singleJumpJumps"],
        display: "Single Leg Jumps",
        style: { textAlign: "center" },
      },
      {
        key: "baselineResults",
        subKeys: ["chestFlyReps", "totalPounds"],
        display: "Chest Fly Pounds",
        style: { textAlign: "center" },
      },
      {
        key: "baselineResults",
        subKeys: ["warmDownJumps"],
        display: "Warm-down Jumps",
        style: { textAlign: "center" },
      },
    ];
    if (this.props.multipleAthletes) {
      dataDisplayDefinitions.unshift({
        key: "athlete",
        display: "Athlete",
        style: { textAlign: "center" },
      });
    }
    return (
      <CommonList
        entities={results}
        dataDisplayDefinitions={dataDisplayDefinitions}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const athletes = getAthletes(state);
  return { athletes };
};

export default connect(mapStateToProps, null)(BaselineComparisonList);
