import React from "react";

import SCAT5InputCommon from "../input/common/scat5-input-common";
import SCAT5InputTextarea from "../input/textarea/scat5-input-textarea";
import SCAT5InputRadio from "../input/radio/scat5-input-radio";
import FormRadioSelectGridContainer from "../../form/radio-select/grid-container/form-radio-select-grid-container";
import FormRows from "../../form/rows/form-rows";
import ScrollToHereOnMount from "../../common/scroll-to-here-on-mount/scroll-to-here-on-mount";

const AthleteBackgroundForm = (): JSX.Element => {
  return (
    <React.Fragment>
      <ScrollToHereOnMount />
      <h2 style={{ textTransform: "uppercase", textAlign: "center" }}>
        Step 1: Athlete Background
      </h2>
      <FormRows>
        <SCAT5InputCommon
          label="Sport / team / school"
          id="organization"
          name="organization"
          type="text"
        />
        <SCAT5InputCommon
          label="Date / time of injury"
          id="date-of-injury"
          name="dateOfInjury"
          type="date"
        />
        <SCAT5InputCommon
          label="Years of education completed"
          id="years-of-education"
          name="yearsOfEducation"
          type="number"
        />
        <SCAT5InputCommon label="Age" id="age" name="age" type="number" />
      </FormRows>
      <FormRadioSelectGridContainer>
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Gender"
          name="gender"
          options={{
            M: "m",
            F: "f",
            Other: "other",
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Dominant Hand"
          name="dominantHand"
          options={{
            left: "left",
            right: "right",
            neither: "neither",
          }}
        />
      </FormRadioSelectGridContainer>
      <FormRows>
        <SCAT5InputCommon
          label="How many diagnosed concussions has the
athlete had in the past?"
          id="number-of-concussions"
          name="numberOfConcussions"
          type="number"
        />
        <SCAT5InputCommon
          label="When was the most recent concussion?"
          id="most-recent-concussion"
          name="mostRecentConcussion"
          type="date"
        />
        <SCAT5InputCommon
          label="How long was the recovery (time to being cleared to play)
from the most recent concussion?: (days)"
          id="days-of-last-recovery-time"
          name="daysOfLastRecoveryTime"
          type="number"
        />
      </FormRows>
      <h3>Has the athlete ever been:</h3>
      <FormRadioSelectGridContainer>
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Hospitalized for a head injury?"
          name="hasBeenHospitalizedForHeadInjury"
          options={{
            yes: true,
            no: false,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Diagnosed / treated for headache disorder or migraines?"
          name="hasBeenTreatedForHeadacheMigraineDisorder"
          options={{
            yes: true,
            no: false,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Diagnosed with a learning disability / dyslexia?"
          name="diagnosedWithLearningDisability"
          options={{
            yes: true,
            no: false,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Diagnosed with ADD / ADHD?"
          name="diagnosedWithADD"
          options={{
            yes: true,
            no: false,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Diagnosed with depression, anxiety
or other psychiatric disorder?"
          name="diagnosedWithPsychDisorder"
          options={{
            yes: true,
            no: false,
          }}
        />
        <SCAT5InputTextarea
          label="Current medications? If yes, please list:"
          id="current-medications"
          name="currentMedications"
        />
      </FormRadioSelectGridContainer>
      <div style={{ height: "40px" }}></div>
    </React.Fragment>
  );
};

export default AthleteBackgroundForm;
