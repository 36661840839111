import { connect } from "react-redux";
import React from "react";
import {
  getSegmentRecordsByIdList,
  toggleSegmentRecordSelection,
  clearSegmentRecordSelection,
  getSessionsBySegmentRecordIdList,
} from "../modules/segment-record/segment-record-actions";
import {
  getSession,
  getSegmentRecord,
  getSegmentRecordResultData,
  getSelectedSegmentRecords,
  getSegmentRecordAthlete,
} from "../selectors";
import UserFeedbackDrawer from "../modules/user-feedback/drawer/user-feedback-drawer";

import AppBar from "../modules/common/app-bar/app-bar";
import SegmentRecordResistanceResultsGraph from "../modules/segment-record/results/graph/resistance/resistance-results-graph";
import SegmentRecordCardioResultsGraph from "../modules/segment-record/results/graph/cardio/cardio-results-graph";
import SegmentRecordFastFeetResultsGraph from "../modules/segment-record/results/graph/fastfeet/fastfeet-results-graph";
import SegmentRecordBaselineResultsGraph from "../modules/segment-record/results/graph/baseline/baseline-results-graph";
import CardioComparisonList from "../modules/comparison-list/cardio/cardio-comparison-list";
import ResistanceComparisonList from "../modules/comparison-list/resistance/resistance-comparison-list";
import FastFeetComparisonList from "../modules/comparison-list/fastfeet/fastfeet-comparison-list";
import BaselineComparisonList from "../modules/comparison-list/baseline/baseline-comparison-list";
import CommonCenterBanner from "../modules/common/banner/center/common-center-banner";
import CommonSplitBanner from "../modules/common/banner/split/common-split-banner";
import CommonStyledDropdown from "../modules/common/styled-dropdown/common-styled-dropdown";
import SideNavigation from "../modules/side-navigation/side-navigation";
import ResultsCardSection from "../modules/segment-record/results/card/section/results-card-section";

class SegmentRecordComparePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comparisonSegmentRecords: this.props.comparisonSegmentRecords,
      displayType: this.props.displayType ? this.props.displayType : "table",
      segmentRecordsLoaded: false,
      resultsLoaded: false,
      resultsPending: false,
      athletesRequested: false,
      multipleAthletes: false,
      extraAthletes: [],
      sorted: false,
    };
    this.setDisplayType = this.setDisplayType.bind(this);
    this.checkForNeededSegmentRecords =
      this.checkForNeededSegmentRecords.bind(this);
    this.setSelectedSegemtnRecordsInState =
      this.setSelectedSegmentRecordsInState.bind(this);
    this.checkIfSegmentRecordsHaveAllBeenLoaded =
      this.checkIfSegmentRecordsHaveAllBeenLoaded.bind(this);
    this.checkForNeededAthletes = this.checkForNeededAthletes.bind(this);
    this.sortSegmentRecordsByDate = this.sortSegmentRecordsByDate.bind(this);
    this.loadResults = this.loadResults.bind(this);
  }

  sortSegmentRecordsByDate() {
    // console.log("%cSorting", "background:orange;");
    const segmentRecords = this.props.comparisonSegmentRecords;
    const sortedSegmentRecords = segmentRecords.slice().sort((b, a) => {
      if (a.segmentRecord && b.segmentRecord) {
        return (
          new Date(b.segmentRecord.segmentRecordStartTime).getTime() -
          new Date(a.segmentRecord.segmentRecordStartTime).getTime()
        );
      }
      return 0;
    });
    this.setState({ comparisonSegmentRecords: sortedSegmentRecords });
  }

  setSelectedSegmentRecordsInState() {
    //If the segmentRecords being compared aren't selected in the state, select them
    if (!this.props.stateBaselineId) {
      this.props.toggleSegmentRecordSelection(
        this.props.baselineSegmentRecordId
      );
    }
    this.props.comparisonSegmentRecords.forEach((comparisonSegmentRecord) => {
      if (
        !this.props.selectedSegmentRecordIds.includes(
          comparisonSegmentRecord.id
        )
      ) {
        this.props.toggleSegmentRecordSelection(comparisonSegmentRecord.id);
      }
    });
  }

  checkIfSegmentRecordsHaveAllBeenLoaded() {
    return (
      this.props.comparisonSegmentRecords.filter(
        (comparisonSegmentRecord) =>
          typeof comparisonSegmentRecord.segmentRecord === "undefined" ||
          typeof comparisonSegmentRecord.segmentRecord.segmentRecordSamples ===
            "undefined"
      ).length == 0
    );
  }

  checkForNeededAthletes() {
    let segmentRecordsMissingAthletes = [];
    if (!this.props.athlete) {
      segmentRecordsMissingAthletes.push(this.props.baselineSegmentRecordId);
    }
    this.props.comparisonSegmentRecords.forEach((comparisonSegmentRecord) => {
      if (typeof comparisonSegmentRecord.athlete === "undefined") {
        segmentRecordsMissingAthletes.push(comparisonSegmentRecord.id);
      }
    });
    // console.log(
    //   "%cNeed Athletes for: ",
    //   "background:yellow;",
    //   segmentRecordsMissingAthletes
    // );
    return segmentRecordsMissingAthletes;
  }

  loadResults() {
    //Calories take the longest to load, so use that and the presense of the athlete object to determine if we're done
    const allLoaded =
      this.props.comparisonSegmentRecords.filter((comparisonSegmentRecord) => {
        //        console.log(comparisonSegmentRecord);
        return (
          typeof comparisonSegmentRecord.results.calories == "undefined" ||
          typeof comparisonSegmentRecord.athlete == "undefined"
        );
      }).length == 0;
    if (allLoaded) {
      const otherAthletesComparisonSegmentRecords =
        this.props.comparisonSegmentRecords.filter(
          (comparisonSegmentRecord) => {
            return (
              comparisonSegmentRecord.athlete.id !=
              this.props.baselineSessionAthleteId
            );
          }
        );
      const otherAthletes = otherAthletesComparisonSegmentRecords.map(
        (comparisonSegmentRecord) => comparisonSegmentRecord.athlete
      );
      const otherAthletesUnique = otherAthletes.filter(
        (x, i, a) => a.indexOf(x) == i
      );
      let multipleAthletes = false;
      if (otherAthletesUnique.length > 0) {
        multipleAthletes = true;
      }
      this.sortSegmentRecordsByDate();
      this.setState({
        resultsLoaded: true,
        multipleAthletes,
        extraAthletes: otherAthletesUnique,
      });
      // console.log("%cSegmentRecordResults Loaded", "background:lightgreen");
    } else {
      // console.log("missing something");
    }
  }

  componentDidUpdate() {
    if (!this.state.segmentRecordsLoaded) {
      if (this.checkIfSegmentRecordsHaveAllBeenLoaded()) {
        this.setState({ segmentRecordsLoaded: true });
        // console.log("%cSegmentRecords Loaded", "background:lightgreen");
        this.setSelectedSegmentRecordsInState();
      }
    } else {
      if (!this.state.resultsLoaded) {
        const segmentRecordsMissingAthletes = this.checkForNeededAthletes();
        if (segmentRecordsMissingAthletes.length > 0) {
          this.props.getSessionsBySegmentRecordIdList(
            segmentRecordsMissingAthletes
          );
        }
        this.loadResults();
      }
    }
  }

  checkForNeededSegmentRecords() {
    let segmentRecordsToGet = [];

    if (
      !this.props.baselineSegmentRecord ||
      typeof this.props.baselineSegmentRecord.segmentRecordSamples ===
        "undefined"
    ) {
      segmentRecordsToGet.push(this.props.baselineSegmentRecordId);
    }

    this.props.comparisonSegmentRecords.forEach((comparisonSegmentRecord) => {
      const id = comparisonSegmentRecord.id;
      if (
        !comparisonSegmentRecord.segmentRecord ||
        typeof comparisonSegmentRecord.segmentRecord.segmentRecordSamples ===
          "undefined"
      ) {
        segmentRecordsToGet.push(id);
      }
    });
    // console.log(
    //   "%cNeeded SegmentRecords: ",
    //   "background:yellow;",
    //   segmentRecordsToGet
    // );
    return segmentRecordsToGet;
  }

  setDisplayType(value) {
    this.setState({ displayType: value });
    this.props.history.push(`${this.props.cleanBaseURL}&display=${value}`);
  }

  componentDidMount() {
    const segmentRecordsToGet = this.checkForNeededSegmentRecords();
    if (segmentRecordsToGet.length > 0) {
      this.props.getSegmentRecordsByIdList(segmentRecordsToGet);
    } else {
      this.setState({ segmentRecordsLoaded: true });
      // console.log("%cSegmentRecords Loaded", "background:lightgreen");
      this.loadResults();
      this.setSelectedSegmentRecordsInState();
    }
  }

  render() {
    let name;
    let athleteId;
    //      let athleteButton;
    if (this.props.athlete) {
      name = this.props.athlete.name;
      athleteId = this.props.athlete.id;
      //        athleteButton = <button onClick={()=>this.props.history.push(`/athlete/${athleteId}`)}>Back to {name}</button>;
    }
    let comparison;
    if (!(this.state.resultsLoaded && this.props.athlete)) {
      comparison = <div>Loading...</div>;
    } else {
      const cards = (
        <ResultsCardSection
          comparisonSegmentRecords={this.state.comparisonSegmentRecords}
          athletes={this.props.athletes}
          baselineSegmentRecord={this.props.baselineSegmentRecord}
          baselineResultData={this.props.baselineResultData}
          baselineAthlete={this.props.athlete}
          multipleAthletes={this.state.multipleAthletes}
        />
      );
      switch (this.state.displayType) {
        case "table":
          {
            let cardioTable;
            let fastFeetTable;
            let baselineTable;
            let resistanceTable;
            if (this.props.baselineResultData.resultTypes) {
              if (this.props.baselineResultData.resultTypes.targetedCardio) {
                cardioTable = (
                  <CardioComparisonList
                    baselineSegmentRecord={this.props.baselineSegmentRecord}
                    baselineResultData={this.props.baselineResultData}
                    baselineAthlete={this.props.athlete}
                    multipleAthletes={this.state.multipleAthletes}
                    comparisonSegmentRecords={this.state.comparisonSegmentRecords.filter(
                      (comp) => comp.results.resultTypes.targetedCardio
                    )}
                  />
                );
              }
              if (this.props.baselineResultData.resultTypes.resistance) {
                resistanceTable = (
                  <ResistanceComparisonList
                    baselineSegmentRecord={this.props.baselineSegmentRecord}
                    baselineResultData={this.props.baselineResultData}
                    baselineAthlete={this.props.athlete}
                    multipleAthletes={this.state.multipleAthletes}
                    comparisonSegmentRecords={this.state.comparisonSegmentRecords.filter(
                      (comp) => comp.results.resultTypes.resistance
                    )}
                  />
                );
              }
              if (this.props.baselineResultData.resultTypes.fastFeet) {
                fastFeetTable = (
                  <FastFeetComparisonList
                    baselineSegmentRecord={this.props.baselineSegmentRecord}
                    baselineResultData={this.props.baselineResultData}
                    baselineAthlete={this.props.athlete}
                    multipleAthletes={this.state.multipleAthletes}
                    comparisonSegmentRecords={this.state.comparisonSegmentRecords.filter(
                      (comp) => comp.results.resultTypes.fastFeet
                    )}
                  />
                );
              }
              if (this.props.baselineResultData.resultTypes.baseline) {
                baselineTable = (
                  <BaselineComparisonList
                    baselineSegmentRecord={this.props.baselineSegmentRecord}
                    baselineResultData={this.props.baselineResultData}
                    baselineAthlete={this.props.athlete}
                    multipleAthletes={this.state.multipleAthletes}
                    comparisonSegmentRecords={this.state.comparisonSegmentRecords.filter(
                      (comp) => comp.results.resultTypes.fastFeet
                    )}
                  />
                );
              }
            }
            comparison = (
              <React.Fragment>
                {cardioTable}
                {fastFeetTable}
                {baselineTable}
                {resistanceTable}
              </React.Fragment>
            );
          }
          break;
        case "cards":
          {
            comparison = cards;
          }
          break;
        case "graph":
          {
            let cardioGraph;
            let resistanceGraph;
            let fastFeetGraph;
            let baselineGraphs;
            if (this.props.baselineResultData.resultTypes) {
              if (this.props.baselineResultData.resultTypes.targetedCardio) {
                cardioGraph = (
                  <SegmentRecordCardioResultsGraph
                    history={this.props.history}
                    baselineSegmentRecord={this.props.baselineSegmentRecord}
                    baselineResultData={this.props.baselineResultData}
                    multipleAthletes={this.state.multipleAthletes}
                    comparisonSegmentRecords={
                      this.state.comparisonSegmentRecords
                    }
                  />
                );
              }
              if (this.props.baselineResultData.resultTypes.resistance) {
                resistanceGraph = (
                  <SegmentRecordResistanceResultsGraph
                    history={this.props.history}
                    baselineSegmentRecord={this.props.baselineSegmentRecord}
                    baselineResultData={this.props.baselineResultData}
                    multipleAthletes={this.state.multipleAthletes}
                    comparisonSegmentRecords={
                      this.state.comparisonSegmentRecords
                    }
                  />
                );
              }
              if (this.props.baselineResultData.resultTypes.fastFeet) {
                fastFeetGraph = (
                  <SegmentRecordFastFeetResultsGraph
                    history={this.props.history}
                    baselineSegmentRecord={this.props.baselineSegmentRecord}
                    baselineResultData={this.props.baselineResultData}
                    multipleAthletes={this.state.multipleAthletes}
                    comparisonSegmentRecords={
                      this.state.comparisonSegmentRecords
                    }
                  />
                );
              }
              if (this.props.baselineResultData.resultTypes.baseline) {
                baselineGraphs = (
                  <SegmentRecordBaselineResultsGraph
                    history={this.props.history}
                    baselineSegmentRecord={this.props.baselineSegmentRecord}
                    baselineResultData={this.props.baselineResultData}
                    multipleAthletes={this.state.multipleAthletes}
                    comparisonSegmentRecords={
                      this.state.comparisonSegmentRecords
                    }
                  />
                );
              }
            }
            comparison = (
              <React.Fragment>
                {cardioGraph}
                {resistanceGraph}
                {fastFeetGraph}
                {baselineGraphs}
              </React.Fragment>
            );
          }
          break;
      }
    }
    //      let extraButtons;
    if (this.state.multipleAthletes) {
      //        extraButtons = this.state.extraAthletes.map(athlete => {
      //          let name;
      //          if (athlete) {name = athlete.name}
      //          return <button key={athlete.id} onClick={()=>this.props.history.push(`/athlete/${athlete.id}`)}>Back to {name}</button>
      //        });
    }

    let tests = [];
    if (this.props.baselineResultData.resultTypes) {
      if (this.props.baselineResultData.resultTypes.targetedCardio) {
        tests.push(<div key="cardio-signifier">Cardio Results</div>);
      }
      if (this.props.baselineResultData.resultTypes.resistance) {
        tests.push(<div key="resistance-signifier">Resistance Results</div>);
      }
    }
    return (
      <div className="page-wrapper">
        <AppBar pageName="Compare Workouts" />
        <CommonSplitBanner
          leftComponent={
            <div>
              <div>Test:</div>
              {tests}
            </div>
          }
          rightComponent={
            <CommonStyledDropdown
              entities={[
                { name: "Table", id: "table" },
                { name: "Graph", id: "graph" },
                { name: "Cards", id: "cards" },
              ]}
              defaultValue={this.state.displayType}
              onChange={this.setDisplayType}
              label="view"
            />
          }
        />
        {comparison}

        <CommonCenterBanner>
          <div>Baseline Athlete:</div>
          <div>{name}</div>
        </CommonCenterBanner>
        <SideNavigation
          leftLink={`/athlete-log/${athleteId}`}
          leftText="athlete log"
          rightLink={"/"}
          rightText="roster"
        />
        <UserFeedbackDrawer />
      </div>
    );
  }
}

//= =========================================
//		MAP STATE TO PROPS
//= =========================================

const mapStateToProps = (state, props) => {
  const searchParams = new URLSearchParams(props.location.search);
  const baselineSegmentRecordId = searchParams.get("baseline_id");
  const displayType = searchParams.get("display");
  const baselineResultData = getSegmentRecordResultData(
    state,
    baselineSegmentRecordId
  );
  const baselineSegmentRecord = getSegmentRecord(
    state,
    baselineSegmentRecordId
  );
  let baselineSessionAthleteId;
  if (baselineSegmentRecord) {
    const baselineSession = getSession(state, baselineSegmentRecord.session_id);
    if (baselineSession) {
      baselineSessionAthleteId = baselineSession.user_id;
    }
  }
  const comparisonSegmentRecordIds = JSON.parse(
    searchParams.get("segment_record_ids")
  );
  const selectedSegmentRecords = getSelectedSegmentRecords(state);
  let selectedSegmentRecordIds = [];
  if (selectedSegmentRecords) {
    selectedSegmentRecordIds = selectedSegmentRecords.map(
      (segmentRecord) => segmentRecord.id
    );
  }
  const cleanBaseURL = `${
    props.location.pathname
  }?baseline_id=${baselineSegmentRecordId}&segment_record_ids=${JSON.stringify(
    comparisonSegmentRecordIds
  )}`;
  const stateBaselineId = state.segmentRecordReducer.baselineId;
  const comparisonSegmentRecords = comparisonSegmentRecordIds.map((id) => {
    return {
      id,
      segmentRecord: getSegmentRecord(state, id),
      results: getSegmentRecordResultData(state, id),
      athlete: getSegmentRecordAthlete(state, id),
    };
  });
  let athlete;
  if (baselineSessionAthleteId) {
    athlete = state.athleteReducer.entities[baselineSessionAthleteId];
  }

  return {
    athlete,
    displayType,
    cleanBaseURL,
    baselineSegmentRecordId,
    baselineResultData,
    baselineSessionAthleteId,
    baselineSegmentRecord,
    comparisonSegmentRecords,
    comparisonSegmentRecordIds,
    selectedSegmentRecordIds,
    stateBaselineId,
  };
};

export default connect(mapStateToProps, {
  getSegmentRecordsByIdList,
  getSessionsBySegmentRecordIdList,
  toggleSegmentRecordSelection,
  clearSegmentRecordSelection,
})(SegmentRecordComparePage);
