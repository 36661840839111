import React from "react";

import SCAT5 from "../scat5";
import SCAT5InstructionsDrawer from "../instructions-drawer/scat5-instructions-drawer";
import SCAT5Info from "../forms/scat5-info";
import PatientDetailsForm from "../forms/patient-details-form";
import OfficeInfo from "../forms/office-info";
import AthleteBackgroundForm from "../forms/athlete-background-form";
import SymptomEvaluation from "../forms/symptom-evaluation";
import CognitiveScreening from "../forms/cognitive-screening";
import NeurologicalScreen from "../forms/neurological-screen";
import DelayedRecall from "../forms/delayed-recall";
import DecisionForm from "../forms/decision-form";

const formList: Array<JSX.Element> = [
  <SCAT5Info key="scat5-info" />,
  <PatientDetailsForm key="scta5-patient-details-form" />,
  <OfficeInfo key="office-info" />,
  <AthleteBackgroundForm key="athlete-background" />,
  <SymptomEvaluation key="symptom-evaluation" />,
  <CognitiveScreening key="cognitive-screening" />,
  <NeurologicalScreen key="neurological-screen" />,
  <DelayedRecall key="delayed-recall" />,
  <DecisionForm key="decision-form" />,
];

const formNames: Array<string> = [
  "scat5Info",
  "patientDetails",
  "office-info",
  "athleteBackground",
  "symptomEvaluation",
  "cognitiveScreening",
  "neurologicalScreen",
  "delayedRecall",
  "decisionForm",
];

interface OfficeSCAT5AssessmentProps {
  onSubmit: (segments: any) => void;
  athlete: any;
  trainer: any;
}

const map = [
  {
    fromForm: "patientDetails",
    toForm: "athleteBackground",
    fields: ["dateOfInjury"],
  },
  {
    fromForm: "cognitiveScreening",
    toForm: "delayedRecall",
    fields: ["sacImmediateMemory"],
  },
  {
    fromForm: "symptomEvaluation",
    toForm: "decisionForm",
    fields: [
      "headache",
      "pressureInHead",
      "neckPain",
      "nauseaOrVomiting",
      "dizziness",
      "blurredVision",
      "balanceProblems",
      "sensitivityToLight",
      "sensitivityToNoise",
      "feelingSlowedDown",
      "feelingLikeInAFog",
      "dontFeelRight",
      "difficultyConcentrating",
      "difficultyRemembering",
      "fatigueOrLowEnergy",
      "confusion",
      "drowsiness",
      "moreEmotional",
      "irritability",
      "sadness",
      "nervousOrAnxious",
      "troubleFallingAsleep",
    ],
  },
  {
    fromForm: "cognitiveScreening",
    toForm: "decisionForm",
    fields: [
      "sacOrientation",
      "sacImmediateMemory",
      "sacDigitsBackwards",
      "sacMonthsInReverse",
    ],
  },
  {
    fromForm: "neurologicalScreen",
    toForm: "decisionForm",
    fields: [
      "doubleLegStanceErrors",
      "singleLegStanceErrors",
      "tandemStanceErrors",
    ],
  },
  {
    fromForm: "delayedRecall",
    toForm: "decisionForm",
    fields: ["accuratelyRecalledWords"],
  },
  {
    fromForm: "patientDetails",
    toForm: "decisionForm",
    fields: ["dateOfInjury"],
  },
];

const OfficeSCAT5Assessment = ({
  onSubmit,
  athlete,
  trainer,
}: OfficeSCAT5AssessmentProps): JSX.Element => {
  return (
    <React.Fragment>
      <SCAT5
        onSubmit={onSubmit}
        formList={formList}
        formNames={formNames}
        map={map}
        athlete={athlete}
        trainer={trainer}
      />
      <SCAT5InstructionsDrawer />
    </React.Fragment>
  );
};

export default OfficeSCAT5Assessment;
