import React from "react";

const OnFieldInfo = (): JSX.Element => {
  return (
    <React.Fragment>
      <h2 style={{ color: "#d2232a" }}>IMMEDIATE OR ON-FIELD ASSESSMENT</h2>
      <div style={{ color: "#fff" }}>
       
      <p>
        The following elements should be assessed for all athletes who are
        suspected of having a concussion prior to proceeding to the
        neurocognitive assessment and ideally should be done on-field after the
        first first aid / emergency care priorities are completed
      </p>
      <p>
        If any of the “Red Flags“ or observable signs are noted after a direct
        or indirect blow to the head, the athlete should be immediately and
        safely removed from participation and evaluated by a physician or
        licensed healthcare professional.
      </p>
      <p>
        Consideration of transportation to a medical facility should be at the
        discretion of the physician or licensed healthcare professional.
      </p>
      <p>
        The GCS is important as a standard measure for all patients and can be
        done serially if necessary in the event of deterioration in conscious
        state. The Maddocks questions and cervical spine exam are critical steps
        of the immediate assessment; however, these do not need to be done
        serially.
      </p>
      </div>
    </React.Fragment>
  );
};

export default OnFieldInfo;
