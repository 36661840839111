import { transcriberActionTypes } from "./transcriber-actions";

export const stateShape = {
  transcriptItems: [],
  generatedNumbers: [],
};

const transcriberReducer = (state = stateShape, action) => {
  switch (action.type) {
    case transcriberActionTypes.receiveTranscriptItems: {
      const newState = { ...state };
      newState.transcriptItems = action.items;
      return newState;
    }
    case transcriberActionTypes.updateGeneratedNumbers: {
      const newState = { ...state };
      newState.generatedNumbers = action.numbers;
      return newState;
    }

    default:
      return state;
  }
};
export default transcriberReducer;
