import React, { useContext } from "react";

export const FormContext = React.createContext({
  values: {},
  setValue: (key: string, value: any) =>
    console.warn("Form Context setValue called but not provided"),
  setValues: (values: Record<string, any>) =>
    console.warn("Form Context setValues called but not provided"),
  athlete: { id: "", name: "", dob: "" },
  trainer: {},
});

export const useFormContextValue = (
  key: string
): number | boolean | string | null => {
  const { values } = useContext(FormContext);
  return values[key] ? values[key] : null;
};
