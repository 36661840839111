import React, { useState } from "react";

interface Props {
  onLoad: (file: string | ArrayBuffer) => void;
  accept?: Array<string>;
}

const FileLoader = ({ onLoad, accept }: Props): JSX.Element => {
  const [file, setFile] = useState<string | ArrayBuffer>("");

  const loadFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      // console.log(e.target.result);
      setFile(e.target.result);
      onLoad(e.target.result);
    };
    reader.readAsText(file.files[0]);
  };

  return (
    <div>
      <input
        type="file"
        id="file-load"
        onChange={(e) => loadFile(e.target)}
        accept={accept ? accept.join(",") : ""}
      />
    </div>
  );
};

export default FileLoader;
