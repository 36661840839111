import React, { ReactNode, ErrorInfo } from "react";
import { connect } from "react-redux";
import { reportError } from "./error-boundary-actions";

interface ErrorBoundaryProps {
  children: ReactNode;
  userId: string;
  reportError: (errorId, userId, error, errorInfo) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorId: string;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorId: "" };
  }

  static getDerivedStateFromError(error: Error): Record<string, boolean> {
    // Update state so the next render will show the fallback UI.
    console.log("theres an error:", error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Send error to server
    // console.log("Uncaught error:", error, "info:", errorInfo);
    const errorId = this.generateErrorId();
    this.setState({ errorId: errorId });
    this.props.reportError(errorId, this.props.userId, error, errorInfo);
  }

  generateErrorId = (): string => {
    return Date.now().toString(16).toUpperCase();
  };

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          <h2>Error ID: {this.state.errorId}</h2>
          {/* <button onClick={() => this.props.history.push("/")}> */}
          {/* Return to home */}
          {/* </button> */}
        </div>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };

//= =========================================
//		MAP STATE TO PROPS
//= =========================================
const mapStateToProps = (state) => {
  const userId = state.accountReducer.currentUserId;
  return { userId };
};

export const ErrorBoundaryComponent = connect(mapStateToProps, { reportError })(
  ErrorBoundary
);
