import { takeEvery, put, all } from "redux-saga/effects";
import { machineActionTypes } from "./machine-reducer";
import { getCurrentSession } from "../session/session-actions";

function* machineSagas() {
  yield all([
    takeEvery(machineActionTypes.getMachineSessions, getMachinesSessionsSaga),
  ]);
}

function* getMachinesSessionsSaga(action) {
  yield all(
    action.machines.map((machine) => put(getCurrentSession(machine.id)))
  );
}

export const sagas = { machineSagas };
