import React, { useState, useEffect, useRef } from "react";
// import TranscriberRecorder from "../transcriber/recorder/transcriber-recorder";
import { MarsdenBallExercise } from "./marsden-ball-exercise";
import { seededRandomNumberList } from "./objects/util";
import { connect } from "react-redux";
import { updateGeneratedNumbers } from "../transcriber/transcriber-actions";

interface MarsdenExercisePageProps {
  startMarsdenSegment: () => void;
  endMarsdenSegment: () => void;
  startSegmentRecord: (string) => void;
  sessionId: string;
  marsdenRadius: number;
  marsdenFontSize: number;
  marsdenNumbersPerRow: number;
  generatedNumbers: Array<number>;
  updateGeneratedNumbers: (generatedNumbers) => void;
}

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const MarsdenExercisePage = ({
  sessionId,
  marsdenRadius,
  marsdenFontSize,
  marsdenNumbersPerRow,
  updateGeneratedNumbers,
}: MarsdenExercisePageProps): JSX.Element => {
  const [count, setCount] = useState(3);
  const [started, setStarted] = useState(false);

  useInterval(
    () => {
      setCount(count - 1);
      if (count == 1) {
        setStarted(true);

        const radius = 10;
        const fontSize = 2.2;
        const numbersPerRow = 5;
        const seed = "berriesANDcream";
        const rowCount = Math.round(radius / fontSize);
        const numberCount = rowCount * numbersPerRow;
        const list = seededRandomNumberList(seed, numberCount);
        updateGeneratedNumbers(list);
        console.log("number list! 2", list, seed);

        MarsdenBallExercise({
          width: 800,
          height: 600,
          radius: marsdenRadius,
          fontSize: marsdenFontSize,
          numbersPerRow: marsdenNumbersPerRow,
          parentId: "marsden-ball-exercise-container",
          seed: sessionId,
          list: list,
        });
      }
    },
    count > 0 ? 1000 : null
  );

  return (
    <div className="page-wrapper">
      <div
        id="marsden-ball-exercise-container"
        style={{ width: "fit-content", margin: "auto" }}
      ></div>
      {/* <TranscriberRecorder /> */}
    </div>
  );
};

export default connect(null, { updateGeneratedNumbers })(MarsdenExercisePage);
