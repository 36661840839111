import React from "react";

import SCAT5InputRadio from "../input/radio/scat5-input-radio";
import FormRadioSelectGridContainer from "../../form/radio-select/grid-container/form-radio-select-grid-container";

const ObservableSigns = (): JSX.Element => {
  return (
    <React.Fragment>
      <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Step 2: Observable Signs
      </h2>
      <SCAT5InputRadio
        modifiers={["horizontal"]}
        label=""
        name="observationType"
        options={{
          Witnessed: "witnessed",
          "Observed On Video": "video",
        }}
      />
      <FormRadioSelectGridContainer>
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Lying motionless on the playing surface"
          name="motionless"
          options={{
            Y: true,
            N: false,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Balance / gait difficulties / motor incoordination: stumbling, slow / laboured movements"
          name="balanceGaitIncoordination"
          options={{
            Y: true,
            N: false,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Disorientation or confusion, or an inability to respond appropriately to questions"
          name="disorientation"
          options={{
            Y: true,
            N: false,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Blank or vacant look"
          name="vacantLook"
          options={{
            Y: true,
            N: false,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Facial injury after head trauma"
          name="facialInjury"
          options={{
            Y: true,
            N: false,
          }}
        />
      </FormRadioSelectGridContainer>
    </React.Fragment>
  );
};

export default ObservableSigns;
