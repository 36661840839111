import React from "react";

import { useFormContextValue } from "../../form-context";
import FormRadioSelectGridContainer from "../../../form/radio-select/grid-container/form-radio-select-grid-container";
import SCAT5InputRadio from "../../input/radio/scat5-input-radio";
import ScoreBox from "../../../form/score-box/score-box";

const SACMonthsInReverse = (): JSX.Element => {
  const score = useFormContextValue("canListMonthsInReverse") == "true" ? 1 : 0;
  return (
    <React.Fragment>
      <FormRadioSelectGridContainer>
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Dec-Nov-Oct-Sept-Aug-Jul-Jun-May-Apr-Mar-Feb-Jan"
          name="canListMonthsInReverse"
          options={{ 0: false, 1: true }}
        />
      </FormRadioSelectGridContainer>
      <ScoreBox label="Months Score">{score} of 1</ScoreBox>
    </React.Fragment>
  );
};

export default SACMonthsInReverse;
