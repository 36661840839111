export const userFeedbackActionTypes = {
  postUserFeedback: "POST_USER_FEEDBACK",
  feedbackSent: "FEEDBACK_SENT",
  feedbackReceived: "FEEDBACK_RECEIVED",
  resetFeedbackState: "RESET_FEEDBACK_STATE",
  feedbackError: "FEEDBACK_ERROR",
};

export const stateShape = { sent: false, received: false, error: false };

const userFeedbackReducer = (state = stateShape, action) => {
  switch (action.type) {
    case "CLEAR_FEEDBACK": {
      const newState = stateShape;
      return newState;
    }

    case userFeedbackActionTypes.feedbackSent: {
      let newState = { ...state };
      newState.sent = true;
      return newState;
    }

    case userFeedbackActionTypes.feedbackReceived: {
      let newState = { ...state };
      newState.received = true;
      return newState;
    }

    case userFeedbackActionTypes.resetFeedbackState: {
      let newState = { ...stateShape };
      return newState;
    }

    case userFeedbackActionTypes.feedbackError: {
      let newState = { ...state };
      newState.error = true;
      return newState;
    }

    default:
      return state;
  }
};

export default userFeedbackReducer;
