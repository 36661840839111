import React, { useContext } from "react";

import SCAT5InputCommon from "../input/common/scat5-input-common";
import FormRows from "../../form/rows/form-rows";
import CommonButton from "../../common/button/common-button";
import ScoreBox from "../../form/score-box/score-box";
import ScrollToHereOnMount from "../../common/scroll-to-here-on-mount/scroll-to-here-on-mount";
import { FormContext } from "../form-context";

const wordLists = {
  a: ["Finger", "Penny", "Blanket", "Lemon", "Insect"],
  b: ["Candle", "Paper", "Sugar", "Sandwich", "Wagon"],
  c: ["Baby", "Monkey", "Perfume", "Sunset", "Iron"],
  d: ["Elbow", "Apple", "Carpet", "Saddle", "Bubble"],
  e: ["Jacket", "Arrow", "Pepper", "Cotton", "Movie"],
  f: ["Dollar", "Honey", "Mirror", "Saddle", "Anchor"],
  g: [
    "Finger",
    "Candle",
    "Penny",
    "Paper",
    "Blanket",
    "Sugar",
    "Lemon",
    "Sandwich",
    "Insect",
    "Wagon",
  ],
  h: [
    "Baby",
    "Elbow",
    "Monkey",
    "Apple",
    "Perfume",
    "Carpet",
    "Sunset",
    "Saddle",
    "Iron",
    "Bubble",
  ],
  i: [
    "Jacket",
    "Dollar",
    "Arrow",
    "Honey",
    "Pepper",
    "Mirror",
    "Cotton",
    "Saddle",
    "Movie",
    "Anchor",
  ],
};

const listKeys = Object.keys(wordLists);

const DelayedRecall = (): JSX.Element => {
  const { values, setValue } = useContext(FormContext);

  const recalledWords = values["accuratelyRecalledWords"] || {};
  const listKey = values["sacImmediateMemory"]
    ? values["sacImmediateMemory"]["selectedSACWordList"] || ""
    : "";

  const list = wordLists[listKey] ? wordLists[listKey] : [];

  const maxScore = list.length;
  const score = Object.keys(recalledWords).length;

  const addRemoveRecalledWord = (word) => {
    const newRecalledWords = { ...recalledWords };
    if (newRecalledWords[word]) {
      delete newRecalledWords[word];
    } else {
      newRecalledWords[word] = true;
    }
    setValue("accuratelyRecalledWords", newRecalledWords);
  };

  return (
    <React.Fragment>
      <ScrollToHereOnMount />
      <h2 style={{ textTransform: "uppercase", textAlign: "center" }}>
        STEP 5: DELAYED RECALL
      </h2>
      <p>
        The delayed recall should be performed after 5 minutes have elapsed
        since the end of the Immediate Recall section. Score 1 pt. for each
        correct response.
      </p>
      <p>
        <i>
          Do you remember that list of words I read a few times earlier? Tell me
          as many words from the list as you can remember in any order.
        </i>
      </p>
      <FormRows>
        <SCAT5InputCommon
          label="Time Started"
          id="time-started"
          name="timeStarted"
          type="time"
        />
      </FormRows>
      <p>
        Word list used in the Immediate Memory portion of the Cognitive
        Screening
      </p>
      {listKeys.map((key) => {
        return (
          <CommonButton
            key={`button-${key}`}
            modifiers={[listKey == key ? "red" : null]}
            onClick={() => console.log("button functionality removed")}
          >
            {key.toUpperCase()}
          </CommonButton>
        );
      })}
      <p>
        Please select each word correctly recalled. Total score equals number of
        words recalled.
      </p>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          rowGap: "10px",
          marginBottom: "10px",
        }}
      >
        {list.map((word) => {
          return (
            <CommonButton
              onClick={() => addRemoveRecalledWord(word)}
              modifiers={[recalledWords[word] ? "red" : null]}
              key={`button-${word}`}
            >
              {word}
            </CommonButton>
          );
        })}
      </div>
      <ScoreBox label="Words recalled accurately">
        {score} of {maxScore}
      </ScoreBox>
    </React.Fragment>
  );
};

export default DelayedRecall;
