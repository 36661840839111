import React from "react";

import TestForm from "../forms/test-form";
import PatientDetailsForm from "../forms/patient-details-form";
import SCAT5Info from "../forms/scat5-info";
import OnFieldInfo from "../forms/on-field-info";
import RedFlags from "../forms/red-flags";
import ObservableSigns from "../forms/observable-signs";
import MemoryAssesmentForm from "../forms/memory-assesment-form";
import GlasgowComaScaleForm from "../forms/glasgow-coma-scale-form";
import CervicalSpineAssessment from "../forms/cervical-spine-assessment";
import SCAT5 from "../scat5";

const formList: Array<JSX.Element> = [
  <SCAT5Info key="scat5-info" />,
  <PatientDetailsForm key="scta5-patient-details-form" />,
  <OnFieldInfo key="on-field-info" />,
  <RedFlags key="red-flags" />,
  <ObservableSigns key="observable-signs" />,
  <MemoryAssesmentForm key="memory-assesment" />,
  <GlasgowComaScaleForm instanceId={1} key="glasgow-scale-1" />,
  <GlasgowComaScaleForm instanceId={2} key="glasgow-scale-2" />,
  <GlasgowComaScaleForm instanceId={3} key="glasgow-scale-3" />,
  <CervicalSpineAssessment key="cervial-spine-assessment" />,
];

const formNames: Array<string> = [
  "scat5Info",
  "patientDetails",
  "onFieldInfo",
  "redFlags",
  "observavbleSigns",
  "memoryAssesment",
  "glasgowScale1",
  "glasgowScale2",
  "glasgowScale3",
  "cervical-spin-assessment",
];

interface OnFieldSCAT5AssesmentProps {
  onSubmit: (segments: any) => void;
  athlete: any;
  trainer: any;
}

const OnFieldSCAT5Assesment = ({
  onSubmit,
  athlete,
  trainer,
}: OnFieldSCAT5AssesmentProps): JSX.Element => {
  return (
    <SCAT5
      onSubmit={onSubmit}
      formList={formList}
      formNames={formNames}
      athlete={athlete}
      trainer={trainer}
    />
  );
};

export default OnFieldSCAT5Assesment;
