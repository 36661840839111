import React from "react";

import "./common-button.scss";

interface CommonButtonProps {
  onClick: (event: React.MouseEvent) => void;
  modifiers?: Array<string>;
  children: string;
}

const CommonButton = (props: CommonButtonProps): JSX.Element => {
  const buttonModifiers = props.modifiers ? props.modifiers : [];
  let buttonClass = "a-commonButton";
  buttonModifiers.forEach((modifier) => {
    buttonClass += ` -${modifier}`;
  });

  return (
    <a tabIndex={0} className={buttonClass} onClick={props.onClick}>
      {props.children}
    </a>
  );
};

export default CommonButton;
