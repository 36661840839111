import React from "react";
import SegmentRecordResultsCard from "../results-card";

import "./results-card-section.scss";

class ResultsCardSection extends React.Component {
  render() {
    const segmentRecordCards = this.props.comparisonSegmentRecords.map(
      (comparisonSegmentRecord) => {
        return (
          <SegmentRecordResultsCard
            key={comparisonSegmentRecord.id}
            athleteName={comparisonSegmentRecord.athlete.name}
            multipleAthletes={this.props.multipleAthletes}
            segmentRecord={comparisonSegmentRecord.segmentRecord}
            resultData={comparisonSegmentRecord.results}
          />
        );
      }
    );
    const cards = [
      <SegmentRecordResultsCard
        key={this.props.baselineSegmentRecord.id}
        multipleAthletes={this.props.multipleAthletes}
        athleteName={this.props.baselineAthlete.name}
        segmentRecord={this.props.baselineSegmentRecord}
        resultData={this.props.baselineResultData}
      />,
      ...segmentRecordCards,
    ];
    return <div className="results-card-section">{cards}</div>;
  }
}

export default ResultsCardSection;
