import { takeEvery, put, select, all } from "redux-saga/effects";
import {
  athleteReceived,
  athleteRegistered,
  athleteRegistrationRequestSent,
  getAthleteById,
  userCodeReceived,
} from "./athlete-actions.js";
import { athleteActionTypes } from "./athlete-reducer";
import { getUserId } from "../../selectors";
import request from "../../my-axios.js";

//= ################################################
//				ENDPOINTS
//= ################################################

const ENDPOINTS = {
  getAll: (trainerId) => `api/v1/athletes/${trainerId}`,
  register: () => `api/v1/athlete/create`,
  bulkRegister: () => `api/v1/athlete/bulk-create`,
  update: (athleteId) => `api/v1/athlete/update/${athleteId}`,
  getById: (id) => `api/v1/athlete/${id}`,
  getByIdWithUserCode: (id, organizationId) =>
    `api/v1/athlete/${id}/${organizationId}`,
  requestUserCode: (machineId) =>
    `api/v1/athlete/get-available-code/${machineId}`,
};

//= ################################################
//				WATCHER FUNCTIONS
//= ################################################

function* athleteSagas() {
  yield all([
    takeEvery(athleteActionTypes.getAll, getAllAthletesSaga),
    takeEvery(athleteActionTypes.registerNewAthlete, registerNewAthleteSaga),
    takeEvery(
      athleteActionTypes.registerNewAthletesInBulk,
      registerNewAthletesInBulkSaga
    ),
    takeEvery(athleteActionTypes.editAthlete, editAthleteSaga),
    takeEvery(athleteActionTypes.getAthleteById, getAthleteByIdSaga),
    takeEvery(athleteActionTypes.getNewAthleteById, getNewAthleteByIdSaga),
    takeEvery(athleteActionTypes.requestUserCode, requestUserCodeSaga),
  ]);
}

//= ################################################
//				WORKER FUNCTIONS
//= ################################################

function* registerNewAthleteSaga(action) {
  try {
    const req = request.post(ENDPOINTS.register(), action.data);
    yield put(athleteRegistrationRequestSent(action.id));
    const response = yield req;
    // console.log(response);
    yield put(athleteRegistered(action.id));
  } catch (exception) {
    if (exception.response.status == 422) {
      const response = exception.response;
      // console.log(response);
      yield put(
        athleteRegistered(
          action.id,
          response.data.errors,
          JSON.parse(response.config.data).athleteData
        )
      );
    }
    //window.testError = exception;
  }
}

function* registerNewAthletesInBulkSaga(action) {
  try {
    const req = request.post(ENDPOINTS.bulkRegister(), action.data);
    // console.log(action.data);
    yield put(athleteRegistrationRequestSent(action.id));
    const response = yield req;
    // console.log(response);
    yield put(athleteRegistered(action.id));
  } catch (exception) {
    if (exception.response.status == 422) {
      const response = exception.response;
      // console.log(response);
      yield put(
        athleteRegistered(
          action.id,
          response.data.errors,
          JSON.parse(response.config.data).athletesData
        )
      );
    }
  }
}

function* editAthleteSaga(action) {
  try {
    const req = request.post(ENDPOINTS.update(action.athleteId), action.data);
    yield put(athleteRegistrationRequestSent(action.id));
    const response = yield req;
    // console.log(response);
    yield put(athleteRegistered(action.id));
    const userId = yield select(getUserId);
    if (action.athleteId == userId) {
      yield put(getAthleteById(action.athleteId));
    }
  } catch (exception) {
    if (exception.response.status == 422) {
      const response = exception.response;
      // console.log(response);
      yield put(
        athleteRegistered(
          action.id,
          response.data.errors,
          JSON.parse(response.config.data).athleteData
        )
      );
    }
    //window.testError = exception;
  }
}

function* getAllAthletesSaga(action) {
  try {
    // console.log("get all saga");
    const req = request.get(ENDPOINTS.getAll(action.trainerId));
    const response = yield req;
    // console.log("res", response);
    let athletes = response.data.athletes.map((athlete) => {
      return { ...athlete, user_code: athlete.pivot.user_code };
    });
    yield put(athleteReceived(athletes));
  } catch (exception) {
    window.testError = exception;
    if (exception.status == 422) {
      // bad form data
      const response = JSON.parse(exception.response.text);
      console.error(response);
    }
  }
}

function* getAthleteByIdSaga(action) {
  try {
    const req = action.trainerId
      ? request.get(
          ENDPOINTS.getByIdWithUserCode(action.id, action.organizationId)
        )
      : request.get(ENDPOINTS.getById(action.id));
    const response = yield req;
    // console.log("RESPNOSE", response);
    yield put(athleteReceived(response.data.athlete));
  } catch (exception) {
    window.testError = exception;
    if (exception.status == 422) {
      // bad form data
      const response = JSON.parse(exception.response.text);
      console.error(response);
    }
  }
}

function* getNewAthleteByIdSaga(action) {
  try {
    // console.log("get by id saga");
    const req = request.get(ENDPOINTS.getById(action.id));
    const response = yield req;
    // console.log("RESPNOSE", response);
    yield put(athleteReceived(response.data.athlete));
  } catch (exception) {
    window.testError = exception;
    if (exception.status == 422) {
      // bad form data
      const response = JSON.parse(exception.response.text);
      console.error(response);
    }
  }
}

function* requestUserCodeSaga(action) {
  try {
    const req = request.get(ENDPOINTS.requestUserCode(action.organizationId));
    const response = yield req;
    // console.log("RESPONSE", response);
    yield put(userCodeReceived(response.data.userCode));
  } catch (exception) {
    console.error(exception);
  }
}

export const sagas = { athleteSagas };
