import { FormContext } from "../form-context";
import React, { useContext } from "react";

interface StandardSubFormProps {
  name: string;
  children: React.ReactNode;
}

const StandardSubForm = ({
  name,
  children,
}: StandardSubFormProps): JSX.Element => {
  const { setValue, values } = useContext(FormContext);
  const formValues = values[name] ?? "";

  const onChange = (key: string, value: any) => {
    const newValues = {
      ...formValues,
      [key]: value,
    };
    setValue(name, newValues);
  };

  return (
    <FormContext.Provider value={{ values: formValues, setValue: onChange }}>
      {children}
    </FormContext.Provider>
  );
};

export default StandardSubForm;
