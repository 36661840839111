import React from "react";
import { connect } from "react-redux";
import SegmentRecordResultsGraph from "../../results-graph";
import {
  getRecordHRLineData,
  getRecordLineData,
  processJumpRecordSegments,
} from "../../graph_utilities";
import { getExerciseDataTypes } from "../../../../../../selectors";
class SegmentRecordFastFeetRecordGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterRate: 12,
      lines: {},
      xAxis: [],
    };
  }

  componentDidMount() {
    const segmentRecord = this.props.segmentRecord;
    // console.log("segment record", segmentRecord);

    let jumpSamplesInRange, xAxis;
    const graphJumpData = processJumpRecordSegments({
      segmentRecord,
      segType: "FASTFEET",
      filterRate: this.state.filterRate,
      segDataType: this.props.exerciseDataTypes.SPEED_FOCUSED_CARDIO,
    });
    jumpSamplesInRange = graphJumpData.jumpSamplesInRange;
    xAxis = graphJumpData.xAxis;

    //if returns none, try segment data type
    if (jumpSamplesInRange.length == 0 && xAxis.length == 0) {
      const graphJumpData = processJumpRecordSegments({
        segmentRecord,
        segType: null,
        filterRate: this.state.filterRate,
        segDataType: this.props.exerciseDataTypes.SPEED_FOCUSED_CARDIO,
      });
      jumpSamplesInRange = graphJumpData.jumpSamplesInRange;
      xAxis = graphJumpData.xAxis;
    }

    console.log("HEREEEEE", jumpSamplesInRange, xAxis);

    const heartBPMSamples = segmentRecord.heartBPMSamples;
    const lines = {
      jumps: {
        data: getRecordLineData(jumpSamplesInRange, "validJumps"),
        color: "#6FF384",
        duration: 2000,
        label: "Score",
      },
      heartBPM: {
        data: getRecordHRLineData(heartBPMSamples),
        color: "#d2232a",
        duration: 4000,
        label: "Heart Rate",
      },
    };

    this.setState({
      lines,
      xAxis,
    });
  }

  render() {
    const lines = this.state.lines;
    const xAxis = this.state.xAxis;
    let page = <div>Loading Graph...</div>;
    if (xAxis.length > 0) {
      page = (
        <div>
          <SegmentRecordResultsGraph
            lines={lines}
            chartLabel="Speed Steps Record"
            xAxis={xAxis}
            baselineSegmentRecord={{}}
            scale="mseconds"
            history={this.props.history}
          />
        </div>
      );
    }
    return page;
  }
}

const mapStateToProps = (state, props) => {
  const segmentRecord = state.segmentRecordReducer.entities[props.sessionId];
  const exerciseDataTypes = getExerciseDataTypes();

  return {
    segmentRecord,
    exerciseDataTypes,
  };
};

export default connect(mapStateToProps, {})(SegmentRecordFastFeetRecordGraph);
