import React from "react";

const OfficeInfo = (): JSX.Element => {
  return (
    <React.Fragment>
      <h2 style={{ color: "#d2232a" }}>OFFICE OR OFF-FIELD ASSESSMENT</h2>
      <p>
        <b>
          Please note that the neurocognitive assessment should be done in a
          distraction-free environment with the athlete in a resting state.
        </b>
      </p>
    </React.Fragment>
  );
};

export default OfficeInfo;
