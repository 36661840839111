import { connect } from "react-redux";
import React from "react";
import CommonList from "../../common/list/common-list";
import { Link } from "react-router-dom";
import { startSession } from "../../session/session-actions";
import CommonButton from "../../common/button/common-button.tsx";

import "./roster-list.scss";

class RosterList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entities: null,
      matches: window.matchMedia("(min-width: 760px)").matches,
    };
    this.startSession = this.startSession.bind(this);
    this.checkMidWidth = this.checkMidWidth.bind(this);
  }

  startSession(athleteId) {
    // console.log(
    //   "start session for",
    //   athleteId,
    //   this.props.trainerId,
    //   this.props.machineId
    // );
    this.props.startSession(
      athleteId,
      this.props.trainerId,
      this.props.machineId,
      "lorem ipsum data"
    );
    this.props.history.push("/current-session/");
  }

  checkMidWidth() {
    if (window.matchMedia("(min-width: 760px)").matches) {
      this.setState({ matches: true });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.checkMidWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkMidWidth);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.athletes != this.props.athletes) {
      const { athletes } = this.props;
      let entities = {};
      Object.keys(athletes).forEach((athleteId) => {
        //if (athleteId != this.props.trainerId) {
        entities[athleteId] = { ...athletes[athleteId] };
        entities[athleteId].button = (
          <div className="start-session__button__container">
            <CommonButton
              modifiers={["red"]}
              onClick={() => this.startSession(athleteId)}
            >
              Start
            </CommonButton>
          </div>
        );
        const selectedOrganizationPosistion = athletes[
          athleteId
        ].organizations.findIndex((org) => {
          return (
            org.id == this.props.organizationId && org.pivot.role == "athlete"
          );
        });
        if (selectedOrganizationPosistion == -1) {
          console.log(athletes[athleteId]);
        }
        if (selectedOrganizationPosistion > -1) {
          entities[
            athleteId
          ].user_code = `(${athletes[athleteId].organizations[selectedOrganizationPosistion].pivot.user_code})`;
        }

        const name = entities[athleteId].name;
        const splitName = name.split(" ");
        const remixedName =
          splitName.length > 1
            ? `${splitName.slice(-1)}, ${splitName.slice(0, -1).join(" ")}`
            : name;
        entities[athleteId].name = (
          <Link className="athlete-link" to={`/athlete-log/${athleteId}`}>
            {remixedName}
          </Link>
        );
        entities[athleteId].sortName = `${remixedName}.${athleteId}`;
        //}
      });
      this.setState({ entities });
    }
  }

  render() {
    const nameStyle = this.state.matches
      ? { width: "1%", whiteSpace: "nowrap" }
      : { width: "1%" };
    const dataDisplayDefinitions = [
      { key: "name", display: "Athlete", style: nameStyle },
      { key: "user_code", display: "" },
      { key: "button", display: "" },
    ];

    return (
      <React.Fragment>
        <CommonList
          sortBy="sortName"
          entities={this.state.entities}
          dataDisplayDefinitions={dataDisplayDefinitions}
        />
        <div
          className="add-athlete-button"
          onClick={() =>
            this.props.history.push(`/add-athlete/${this.props.machineId}`)
          }
        >
          + Add New Athlete
        </div>
      </React.Fragment>
    );
  }
}

export { RosterList };

//= =========================================
//		MAP STATE TO PROPS
//= =========================================

const mapStateToProps = (state) => {
  let trainerId = state.accountReducer.currentUserId;
  let machineId = state.machineReducer.selectedMachineId;
  let organizationId = state.accountReducer.selectedOrganization;
  return { trainerId, machineId, organizationId };
};

export default connect(mapStateToProps, { startSession })(RosterList);
