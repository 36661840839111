export const transcriberActionTypes = {
  sendRecording: "SEND_RECORDING",
  receiveTranscriptItems: "RECEIVE_TRANSCRIPT_ITEMS",
  updateGeneratedNumbers: "UPDATE_GENERATED_NUMBERS",
};

export function sendRecording(recording: any) {
  return { type: transcriberActionTypes.sendRecording, recording };
}

export function receiveTranscriptItems(items: Array<number>) {
  return { type: transcriberActionTypes.receiveTranscriptItems, items };
}

export function updateGeneratedNumbers(numbers: any) {
  return { type: transcriberActionTypes.updateGeneratedNumbers, numbers };
}
