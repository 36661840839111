import React from "react";

interface PropTypes {
  label: string;
  name: string;
  value: string;
  id: string;
  onChange: React.ChangeEventHandler;
}

const FormTextarea = ({
  label,
  name,
  value,
  id,
  onChange,
}: PropTypes): JSX.Element => {
  return (
    <React.Fragment>
      <label className="a-formTextArea__label" htmlFor={id}>
        {label}
      </label>
      <textarea
        className="a-formTextArea__input"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
      />
    </React.Fragment>
  );
};

export default FormTextarea;
