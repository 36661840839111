import React from "react";
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryLabel,
  VictoryZoomContainer,
  VictoryBrushContainer,
} from "victory";

import "./results-graph.scss";

class SegmentRecordResultsGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = Object.keys(props.lines).reduce((result, statName) => {
      let selection = {};
      selection[statName] = true;
      return Object.assign(result, selection);
    }, {});

    this.state.xAxis = this.props.xAxis;
    this.state.zoomDomain = this.props.xAxis;

    this.selectLine = this.selectLine.bind(this);
    this.formatTickDate = this.formatTickDate.bind(this);
    this.handleDomainChange = this.handleDomainChange.bind(this);
  }

  componentDidMount() {
    const ticksWithLabels = this.props.xAxis
      .map((tick, index) => (typeof tick.label !== "undefined" ? index : ""))
      .filter(String);
    this.setState({ ticksWithLabels });
  }

  handleDomainChange(domain) {
    // console.log(domain.x);
    this.setState({ zoomDomain: domain });
    const xAxis = this.props.xAxis.filter(
      (tick) => tick.date >= domain.x[0] && tick.date <= domain.x[1]
    );
    // console.log(new Date(this.props.xAxis[0].date).getTime());
    // console.log(new Date(domain.x[0]).getTime());
    // console.log(xAxis);
    const ticksWithLabels = xAxis
      .map((tick, index) => (typeof tick.label !== "undefined" ? index : ""))
      .filter(String);
    this.setState({ ticksWithLabels, xAxis });
  }

  formatTickDate(date) {
    const dateObj = new Date(date);
    // const month = dateObj.getMonth() + 1;
    // const day = dateObj.getDate();
    // const year = dateObj.getFullYear();
    const hoursGot = dateObj.getHours();
    const hours = hoursGot > 12 ? hoursGot - 12 : hoursGot;
    const ampm = hoursGot > 12 ? "PM" : "AM";
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    if (this.props.scale == "mseconds") {
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");
      // const mseconds = dateObj.getMilliseconds().toString().padStart(3, "0");
      return `${hours}:${minutes}:${seconds} ${ampm}`;
    }
    return hours + ":" + minutes + " " + ampm;
  }

  selectLine(e) {
    this.setState({ [e.target.value]: e.target.checked });
  }

  render() {
    const lines = this.props.lines;
    const xAxis = this.state.xAxis;
    const baselineAxisPosition = xAxis.findIndex(
      (x) => x.id == this.props.baselineSegmentRecord.id
    );
    const options = Object.keys(lines).map((statName) => {
      const lineColor = lines[statName].color;
      const bgColor = this.state[statName] ? lineColor : "white";
      const labelText = lines[statName].label
        ? lines[statName].label
        : statName;
      return (
        <div
          className="results-graph__legend__key"
          key={`${statName}_input-container`}
        >
          <label
            className="results-graph__legend__key__checkbox"
            key={`${statName}_label`}
            htmlFor={`${statName}_checkbox`}
          >
            <input
              style={{ borderColor: lineColor, backgroundColor: bgColor }}
              key={`${statName}_checkbox`}
              checked={this.state[statName]}
              type="checkbox"
              id={`${statName}_checkbox`}
              onChange={(e) =>
                this.setState({ [e.target.value]: e.target.checked })
              }
              value={statName}
            ></input>
            <span className="results-graph__legend__key__label-text">
              {labelText}
            </span>
          </label>
        </div>
      );
    });
    let firstTime;
    let lastTime;
    let startRange;
    let endRange;
    let domain = {};
    if (this.props.scale == "mseconds") {
      firstTime = new Date(this.props.xAxis[0].date);
      lastTime = new Date(this.props.xAxis[this.props.xAxis.length - 1].date);
      startRange = firstTime.setMilliseconds(firstTime.getMilliseconds() - 100);
      endRange = lastTime.setMilliseconds(lastTime.getMilliseconds() + 100);
      domain = { x: [startRange - 15000, endRange + 5000] };
    }
    const victoryLines = Object.keys(lines).map((statName) => {
      const line = lines[statName];
      // console.log(statName, line.data)
      if (this.state[statName]) {
        return (
          <VictoryLine
            key={statName}
            animate={{ duration: 2000, onLoad: { duration: line.duration } }}
            labelComponent={
              <VictoryLabel y={60} dx={0} style={{ fontSize: 30 }} />
            }
            domain={domain}
            style={{ data: { stroke: line.color, strokeWidth: 6 } }}
            data={line.data}
          />
        );
      }
    });

    const brushLines = Object.keys(lines).map((statName) => {
      const line = lines[statName];
      // console.log(statName, line.data)
      if (this.state[statName]) {
        return (
          <VictoryLine
            key={statName}
            animate={{ duration: 2000, onLoad: { duration: line.duration } }}
            labelComponent={
              <VictoryLabel y={60} dx={0} style={{ fontSize: 30 }} />
            }
            domain={domain}
            style={{ data: { stroke: line.color, strokeWidth: 1 } }}
            data={line.data}
          />
        );
      }
    });

    let tickEvents;
    if (this.props.linkPage) {
      tickEvents = [
        {
          target: "tickLabels",
          eventHandlers: {
            onClick: () => {
              return {
                target: "tickLabels",
                mutation: (props) => {
                  this.props.history.push(
                    `${this.props.linkPage}/${xAxis[props.datum - 1].id}`
                  );
                },
              };
            },
            onMouseOver: () => {
              return {
                target: "tickLabels",
                mutation: () => {
                  return {
                    style: {
                      fill: "blue",
                      fontSize: 30,
                      fontFamily:
                        "'Gill Sans', 'Seravek', 'Trebuchet MS', sans-serif",
                    },
                  };
                },
              };
            },
            onMouseOut: () => {
              return {
                target: "tickLabels",
                mutation: () => {
                  return {
                    style: {
                      fill: "#252525",
                      fontSize: 30,
                      fontFamily:
                        "'Gill Sans', 'Seravek', 'Trebuchet MS', sans-serif",
                    },
                  };
                },
              };
            },
          },
        },
      ];
    }
    const ticksWithLabels = this.state.ticksWithLabels;

    let brush = <React.Fragment></React.Fragment>;
    if (this.props.zoomable && this.state.ticksWithLabels) {
      brush = (
        <VictoryChart
          padding={{ top: 0, left: 50, right: 50, bottom: 30 }}
          width={600}
          height={100}
          scale={{ x: "time" }}
          containerComponent={
            <VictoryBrushContainer
              brushDimension="x"
              brushDomain={this.state.zoomDomain}
              onBrushDomainChange={(domain) => this.handleDomainChange(domain)}
            />
          }
        >
          <VictoryAxis
            dependentAxis
            crossAxis
            style={{ tickLabels: { fontSize: 10 } }}
            standalone={false}
          />
          <VictoryAxis
            crossAxis
            scale={{ x: "time" }}
            style={{
              axisLabel: { padding: 200 },
              grid: {
                stroke: ({ index }) => {
                  if (ticksWithLabels.length > 0) {
                    if (ticksWithLabels.includes(index)) {
                      //console.log('dd',index)
                      const label = xAxis[index].label;
                      if (label[0] == "R") {
                        if (label[1] == "S") {
                          return "red";
                        } else {
                          return "green";
                        }
                      }
                      return "grey";
                    }
                    return "none";
                  }
                  return index == baselineAxisPosition ? "#48ebcf" : "grey";
                },
              },
              tickLabels: { angle: 45, fontSize: 10, padding: 90 },
            }}
            fixLabelOverlap={this.props.fixOverlap}
            events={tickEvents}
            tickValues={xAxis.map((datum) => new Date(datum.date))}
            tickFormat={xAxis.map((datum) => {
              if (ticksWithLabels.length > 0) {
                if (typeof datum.label !== "undefined") {
                  return this.formatTickDate(datum.date);
                } else {
                  return "";
                }
              } else {
                return this.formatTickDate(datum.date);
              }
            })}
            standalone={false}
          />
          {brushLines}
        </VictoryChart>
      );
    }
    if (ticksWithLabels) {
      //console.log(ticksWithLabels)
      //    console.log(xAxis)
      //console.log('len',xAxis.length)
      //console.log('tick', ticksWithLabels.map(i => {return {index: i, data:xAxis[i]} }))
      return (
        <div className="results-graph__container">
          <div className="results-graph__title">{this.props.chartLabel}</div>
          <div className="results-graph__legend__container">{options}</div>
          <VictoryChart
            width={1400}
            height={650}
            padding={{ bottom: 200, left: 110, right: 110, top: 20 }}
            containerComponent={
              <VictoryZoomContainer
                zoomDimension="x"
                disable={!this.props.zoomable}
                zoomDomain={this.state.zoomDomain}
                onZoomDomainChange={(domain) => this.handleDomainChange(domain)}
              />
            }
          >
            <VictoryAxis
              dependentAxis
              crossAxis
              style={{ tickLabels: { fontSize: 30 } }}
              standalone={false}
            />
            <VictoryAxis
              crossAxis
              scale={{ x: "time" }}
              style={{
                axisLabel: { padding: 200 },
                grid: {
                  stroke: ({ index }) => {
                    if (ticksWithLabels.length > 0) {
                      if (ticksWithLabels.includes(index)) {
                        //console.log('dd',index)
                        const label = xAxis[index].label;
                        if (label[0] == "R") {
                          if (label[1] == "S") {
                            return "red";
                          } else {
                            return "green";
                          }
                        }
                        return "grey";
                      }
                      return "none";
                    }
                    return index == baselineAxisPosition ? "#48ebcf" : "grey";
                  },
                },
                tickLabels: { angle: 45, fontSize: 30, padding: 90 },
              }}
              fixLabelOverlap={this.props.fixOverlap}
              events={tickEvents}
              tickValues={xAxis.map((datum) => new Date(datum.date))}
              tickFormat={xAxis.map((datum) => {
                if (ticksWithLabels.length > 0) {
                  if (typeof datum.label !== "undefined") {
                    return this.formatTickDate(datum.date);
                  } else {
                    return "";
                  }
                } else {
                  return this.formatTickDate(datum.date);
                }
              })}
              standalone={false}
            />
            {victoryLines}
          </VictoryChart>
          {brush}
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

export default SegmentRecordResultsGraph;
