import { takeEvery, put, all } from "redux-saga/effects";
import {
  accountSignedIn,
  loggedOut,
  setLoginAttemptStatusMessage,
  setLoginAttemptStatusType,
} from "./account-actions";
import { accountActionTypes } from "./account-reducer.js";
import request from "../../my-axios.js";
import {
  machineReceived,
  getMachineSessions,
  selectMachine,
  clearMachines,
} from "../machine/machine-actions";
import { clearAthletes } from "../athlete/athlete-actions";
import { clearSessions } from "../session/session-actions";
import { clearFeedback } from "../user-feedback/user-feedback-actions";

//= ################################################
//				ENDPOINTS
//= ################################################

const ENDPOINTS = {
  login: () => "api/v1/login",
  checkLogin: () => "api/v1/current-user",
  logout: () => "api/v1/logout",
};

//= ################################################
//				WATCHER FUNCTIONS
//= ################################################

function* accountSagas() {
  yield all([
    takeEvery(accountActionTypes.login, loginSaga),
    takeEvery(accountActionTypes.checkLogin, checkLoginSaga),
    takeEvery(accountActionTypes.logOut, logOutSaga),
  ]);
}

//= ################################################
//				WORKER FUNCTIONS
//= ################################################

function* loginSaga(action) {
  try {
    var data = { email: action.username, password: action.password };
    yield put(setLoginAttemptStatusMessage("Logging in..."));
    yield put(setLoginAttemptStatusType("info"));
    const req = request.post(ENDPOINTS.login(), data);
    const response = yield req;
    const { token } = response.data;
    request.setToken(token);
    yield put(setLoginAttemptStatusMessage("Login Successful"));
    yield put(setLoginAttemptStatusType("success"));
    yield checkLoginSaga();
  } catch (exception) {
    console.error(exception);
    yield put(setLoginAttemptStatusMessage("Incorrect Login"));
    yield put(setLoginAttemptStatusType("error"));
  }
}

function* checkLoginSaga() {
  try {
    let token = request.getToken();
    if (token) {
      //return user
      const req = request.get(ENDPOINTS.checkLogin());
      const response = yield req;
      // console.log("RESPONSE", response.data.machines);
      if (response.data.user) {
        yield put(accountSignedIn(response.data.user));
        yield put(machineReceived(response.data.machines));
        yield put(selectMachine(response.data.machines[0].id));
        yield put(getMachineSessions(response.data.machines));
      }
    } else {
      //user needs to log in
      // console.log("user needs to log in");
    }
  } catch (exception) {
    window.testError = exception;
    if (exception.status == 422) {
      // bad form data
      // const response = JSON.parse(exception.response.text);
      // console.log(response.message);
    }
  }
}

function* logOutSaga() {
  try {
    const req = request.post(ENDPOINTS.logout());

    const response = yield req;
    // console.log("RESPONSE", response.data);
    request.resetToken();
    yield put(loggedOut());
    yield put(clearAthletes());
    yield put(clearMachines());
    yield put(clearSessions());
    yield put(clearFeedback());
    localStorage.setItem("currentSessionId", null);
  } catch (exception) {
    window.testError = exception;
    if (exception.status == 422) {
      // bad form data
      // const response = JSON.parse(exception.response.text);
      // console.log(response.message);
    }
  }
}

export const sagas = { accountSagas };
